import { firestoreAction } from 'vuexfire'
import { db, FieldValue, uploadFileAndGetUrl } from '@/services/firebase'
import { strToUniqueId } from '@/utils/formatters'

export default {
  namespaced: true,
  state: () => ({
    dbBuys: [],
  }),
  getters: {
    // TODO: review payments migration
    payments: state => state.dbBuys.filter(b => b.payment.status === 'complete').map(({ payment, ...rest }) => ({ ...rest, buyId: rest.id, ...payment })),
  },
  actions: {
    bindBuys: firestoreAction(({ bindFirestoreRef }, { auTripId }) => bindFirestoreRef('dbBuys', db.collection('buys').where('auTripId', '==', auTripId))),
    // Create
    async create(context, { organizationId, clusterId, publish, data }) {
      const auTripRef = db.collection(`properties/${organizationId}/auTrips`).doc(strToUniqueId(data.name))
      const storagePath = `properties/${organizationId}/auTrips/${auTripRef.id}`
      const image = await uploadFileAndGetUrl(storagePath, data.image)
      const createdAt = FieldValue.serverTimestamp()
      const auTrip = { ...data, id: auTripRef.id, clusterId, isActive: publish, image, createdAt, updatedAt: createdAt }
      await auTripRef.set(auTrip)
      return auTripRef.id
    },
    // Update
    async update(context, { organizationId, auTripId, publish, data }) {
      const storagePath = `properties/${organizationId}/auTrips/${auTripId}`
      const image = await uploadFileAndGetUrl(storagePath, data.image)
      const updatedAt = FieldValue.serverTimestamp()
      const dataToUpdate = { ...data, isActive: publish, image, updatedAt }
      await db.collection(`properties/${organizationId}/auTrips`).doc(auTripId).update(dataToUpdate)
      return auTripId
    },
    async updateMaxStock(context, { organizationId, auTripId, maxStock }) {
      try {
        return await db.runTransaction(async transaction => {
          const auTripRef = db.collection(`properties/${organizationId}/auTrips`).doc(auTripId)
          const auTrip = (await transaction.get(auTripRef)).data()
          if (maxStock >= ((auTrip.maxStock ?? 0) - (auTrip.stock ?? 0))) {
            const newMaxStock = maxStock - (auTrip.maxStock ?? 0)
            transaction.update(auTripRef, { maxStock, stock: FieldValue.increment(newMaxStock) })
            return true
          }
          return false
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
        return false
      }
    },
    async markAsReviewed(context, { organizationId, auTripId, isReviewed }) {
      const updatedAt = FieldValue.serverTimestamp()
      const dataToUpdate = { isReviewed, updatedAt }
      await db.collection(`properties/${organizationId}/auTrips`).doc(auTripId).update(dataToUpdate)
    },
    // Delete
    delete: (context, { organizationId, auTripId }) => db.collection(`properties/${organizationId}/auTrips`).doc(auTripId).delete(),
  },
}
