import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'
import { strToUniqueId } from '@/utils/formatters'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
  }),
  getters: {
    data: state => state.dbData,
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId, teamId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),

    // Create
    async create(context, { organizationId, projectId, clubId, data }) {
      const team = { ...data, id: `${clubId}-${strToUniqueId('')}`, clubId }
      await callBackend('teams/create', { organizationId, projectId, cohortId: data.cohortId, data: team })
      // logEvent({ action: 'create', entityType: 'team', entity: team })
      return team.id
    },

  },
}
