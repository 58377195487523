import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import router from '@/router'
import sitesUrls from '@/enums/rolesEnum'
import firebaseConfig from '@/config/firebase'

if (process.env.VUE_APP_IS_PRODUCTION === 'true') {
  Sentry.init({
    Vue,
    dsn: 'https://33f3a9c451eb4dd785ce7a3286e698e9@o290190.ingest.sentry.io/5444705',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [sitesUrls[firebaseConfig.projectId]],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    enableTracing: true,
  })
}
