<template>
  <v-row justify="center" class="confirm-action-dialog">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <div class="d-flex justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </div>
        <div v-if="showIcon" class="mb-4">
          <v-icon x-large color="yellow80" v-text="'warning_amber'" />
        </div>
        <slot name="alert" />
        <div v-if="title" class="mb-4">
          <h2 v-text="title" />
        </div>
        <div>
          <v-card-text class="pa-0 mb-4">
            <div class="text-body-1" v-html="text" />
            <slot />
          </v-card-text>
        </div>
        <v-card-actions :class="['justify-space-around align-center mt-4', buttonTruePosition === 'right' ? 'flex-row-reverse' : null]">
          <v-btn class="button-dialog-action" color="primary" :outlined="buttonTrueOutlined" @click="$emit('accept')">
            {{ buttonTrueText }}
          </v-btn>
          <v-btn
            v-if="buttonFalseText" class="button-dialog-action" color="primary" :outlined="buttonFalseOutlined" @click="$emit('closeDialog')"
          >
            {{ buttonFalseText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'ConfirmActionDialog',
  props: {
    showIcon: { type: Boolean, default: true },
    title: { type: String, default: null },
    text: { type: String, default: null },
    buttonTrueText: { type: String, required: true },
    buttonTrueOutlined: { type: Boolean, default: true },
    buttonTruePosition: { type: String, default: 'left' },
    buttonFalseText: { type: String, default: () => i18n.t('common.cancel') },
    buttonFalseOutlined: { type: Boolean, default: false },
  },
}
</script>
