import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'locations',
    name: 'locations',
    component: () => import('@/modules/locations/LocationList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t(`competition.sections.locations-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`competition.sections.locations-${store.getters['project/data']?.type}`),
    },
    props: true,
  },
]
