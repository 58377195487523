import i18n from '@/plugins/i18n'

export default [
  {
    path: 'orders',
    name: 'vendor-list-orders',
    component: () => import('@/modules/orders/views/OrderListView.vue'),
    meta: {
      title: () => `${i18n.t('organization.sections.orders')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.orders'),
    },
    props: true,
  },
  {
    path: 'order/:orderId',
    name: 'vendor-detail-order',
    component: () => import('@/modules/orders/views/OrderDetailView.vue'),
    meta: {
      title: () => `${i18n.t('organization.sections.orders')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.orders'),
    },
    props: true,
  },
]
