import i18n from '@/plugins/i18n'
import store from '@/store'
import { auth } from '@/services/firebase'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/user/Login'),
    meta: {
      title: () => `${i18n.t('user.login')} - OWQLO`,
    },
    props: true,
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/modules/user/Logout'),
    meta: {
      title: () => `${i18n.t('user.logout')} - OWQLO`,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/modules/user/Register'),
    meta: {
      title: () => `${i18n.t('user.register')} - OWQLO`,
    },
  },
  {
    path: '/redirect-after-login',
    name: 'redirectAfterLogin',
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) return next({ name: 'login' })
      if (store.getters['superadmin/isUserSuperadmin']) return next({ name: 'superadmin' })
      const userVendorIds = store.getters['user/managerVendorIds']
      if (userVendorIds?.length) return next({ name: 'vendor', params: { vendorId: userVendorIds[0] } })
      const membershipsToAccept = await store.dispatch('user/getMembershipsToAccept', { userId: auth?.currentUser?.uid })
      if (membershipsToAccept.length) {
        return next({
          path: '/privacy-policy',
          query: {
            organizationId: membershipsToAccept[0].organizationId,
            userId: auth?.currentUser?.uid,
          },
        })
      }
      const userOrganizationIds = [
        ...store.getters['user/adminOrganizationIds'],
        ...store.getters['user/collabOrganizationIds'],
      ]
      if (userOrganizationIds.length) {
        return next({
          name: 'organization-projects',
          params: { organizationId: userOrganizationIds[0] },
        })
      }
      const userClubs = store.getters['user/collabClubs']
      if (userClubs.length) {
        const { organizationId, projectId, id: clubId } = userClubs[0]
        return next({
          name: 'club-edit',
          params: { organizationId, projectId, clubId },
        })
      }
      return next({ name: '404' })
    },
  },
  {
    path: '/login-club/:organizationId/:projectId/:clubId',
    name: 'login-club',
    component: () => import('@/modules/user/LoginClub'),
    meta: {
      title: () => `${i18n.t('user.login')} - OWQLO`,
    },
    props: true,
  },
  {
    path: '/shipping/:shippingId',
    name: 'shipping-delivered',
    component: () => import('@/modules/user/ShippingDelivered.vue'),
    meta: { title: () => `${i18n.t('user.purchase.deliverProduct')} - OWQLO` },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { shippingId } = to.params
      const buys = await store.dispatch('user/readBuys', { shippingId })

      if (!buys.length) {
        next({ name: 'status', params: { type: 'error', title: 'user.purchase.purchaseNotExist' } })
        return
      }
      if (store.getters['user/shipperOrganizationIds'].includes(buys[0]?.organizationId) || store.getters['superadmin/isUserSuperadmin']) {
        if (buys.every(b => b.shipping?.status === 'delivered')) {
          next({ name: 'status', params: { type: 'success', title: 'user.purchase.deliveredSuccess' } })
        } else {
          next()
        }
      } else {
        next({ name: 'status', params: { type: 'error', title: 'user.purchase.notPermissions' } })
      }
    },
  },
]
