import { callBackend } from '@/services/firebase'

export const subscribeToEntity = async ({ userId, entity, lang, isStaff = false }) => {
  const { type, role, organizationId, clusterId, projectId, teamId, vendorId } = entity
  if (type === 'organization') {
    await callBackend('organizations/users/subscribe', { organizationId, userId, role, sendCommunications: true })
    if (role === 'laligaAdmin') await callBackend('organizations/users/subscribe', { organizationId, userId, role: 'admin' })
  } else if (type === 'project') {
    await callBackend('projects/users/subscribe', { organizationId, projectId, userId, role, isStaff })
  } else if (type === 'cluster') {
    await callBackend('clusters/users/subscribe', { organizationId, clusterId, userId, role, lang, sendCommunications: true })
  } else if (type === 'team') {
    callBackend('teams/users/subscribe', { organizationId, projectId, userId, teamId, role, isStaff })
  } else if (type === 'vendor') {
    callBackend('vendors/users/subscribe', { vendorId, userId, role, sendCommunications: true })
  }
}

export const unsubscribeFromEntity = async ({ userId, entity }) => {
  const { type, role, organizationId, clusterId, projectId, teamId, vendorId } = entity
  if (type === 'organization') {
    await callBackend('organizations/users/unsubscribe', { organizationId, userId, role })
  } else if (type === 'project') {
    await callBackend('projects/users/unsubscribe', { organizationId, projectId, userId, role, isStaff: true })
  } else if (type === 'cluster') {
    await callBackend('clusters/users/unsubscribe', { organizationId, clusterId, userId, role })
  } else if (type === 'team') {
    callBackend('teams/users/unsubscribe', { organizationId, projectId, userId, teamId, role, isStaff: true })
  } else if (type === 'vendor') {
    callBackend('vendors/users/unsubscribe', { vendorId, userId, role })
  }
}
