<template>
  <v-app>
    <router-view :key="($route.params.organizationId || $route.query.organizationId || '')" />
    <alert-box />
    <loader />
  </v-app>
</template>

<script>
import '@/plugins/sentry'
import localeUtils from '@/utils/mixins/localeUtils'
import AlertBox from '@/modules/alert/AlertBox'
import Loader from '@/modules/loader/Loader'

export default {
  name: 'App',
  components: {
    AlertBox,
    Loader,
  },
  mixins: [localeUtils],
  created() {
    this.changeLocale(this.$i18n.locale)
  },
}
</script>

<style src='./styles/main.scss' lang="scss"/>
