<template>
  <v-tooltip class="global-tooltip-copy-text" top color="white">
    <template #activator="{ on, attrs }">
      <div :class="{ 'global-tooltip-copy-text--default': !isUserSuperadmin }">
        <slot :activator="isUserSuperadmin ? { on, attrs } : {}" :onClick="copyText" />
      </div>
    </template>
    <div class="tooltip-text pa-2" v-text="textToCopy" />
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalTooltipCopyText',
  props: {
    textToCopy: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters({ isUserSuperadmin: 'superadmin/isUserSuperadmin' }),

  },
  methods: {
    copyText() {
      return this.isUserSuperadmin ? this.copyToClipboard(this.textToCopy) : null
    },
  },
}
</script>
<style lang="scss" scoped>
.global-tooltip-copy-text {
  &--default {
    ::v-deep .v-card--link {
      cursor: default !important;
    }
  }
}
</style>
