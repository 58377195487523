<template>
  <div class="d-flex align-baseline">
    <v-text-field
      :label="label"
      outlined
      dense
      :readonly="!!disabled"
      :disabled="disabled"
      :value="value"
      :error-messages="errorMessages"
      @input="$emit('input', $event)"
      @blur="$emit('blur')"
    >
      <template #append>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon color="primary" v-on="on" @click="copyToClipboard(value)">
              content_copy
            </v-icon>
          </template>
          {{ $t('club.editClub.copyLink') }}
        </v-tooltip>
      </template>
    </v-text-field>
    <div v-if="globalTooltip" class="ml-2">
      <global-tooltip :text="globalTooltip" />
    </div>
  </div>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'CopyTextField',
  components: { GlobalTooltip: () => import('@/components/GlobalTooltip') },
  props: {
    label: { type: String, required: true },
    disabled: { type: Boolean, default: true },
    value: { type: String, default: null },
    errorMessages: { type: String, default: null },
    tooltip: { type: String, default: i18n.t('club.editClub.copyLink') },
    globalTooltip: { type: String, default: null },
  },
}
</script>
