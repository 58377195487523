import { db, FieldValue, runCloudFunction } from '@/services/firebase'
import { DateTime } from 'luxon'
import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  actions: {
    // Create
    async createServiceEnrollment({ rootState, rootGetters }, { organizationId, projectId, clubId, data }) {
      const serviceEnrollmentRef = db.collection(`properties/${organizationId}/projects/${projectId}/clubs/${clubId}/serviceEnrollments`).doc()
      await serviceEnrollmentRef.set({
        ...data,
        id: serviceEnrollmentRef.id,
        createdAt: FieldValue.serverTimestamp(),
      })

      const { contactPerson } = data
      const bookings = data.bookings.map(booking => ({
        ...booking,
        team: rootGetters['club/teams'].find(t => t.id === booking.teamId)?.nameWithCohort,
        date: {
          start: new Date(booking.date.start).toLocaleDateString(),
          end: new Date(booking.date.end).toLocaleDateString(),
        },
      }))
      Promise.all(rootState.project.dbData.accomodationEmails?.map(async email => {
        const to = email
        const params = {
          templateId: 'd-f21e5b4088054c0c9a6a5a4eda9beaf5',
          dynamicTemplateData: {
            projectName: rootState.project.dbData.name,
            lang: rootState.project.dbData.language ?? 'en',
            contactPerson,
            bookings,
          },
        }
        await callBackend('emails/dynamic-template', { to, params })
      }))
      return true
    },
  },
}
