import { runTransaction } from 'firebase/firestore'
import { db, Timestamp, callBackend, FieldValue } from '@/services/firebase'
import getAmountTextFormatCurrency from '@/utils/getAmountTextFormatCurrency'
import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  actions: {
    hasPaymentsCompleteByOrganization: async (context, { organizationId }) => (await db
      .collection('buys').where('organizationId', '==', organizationId).where('payment.status', 'in', ['complete']).limit(1).get()).docs.length,
    hasPaymentsCompleteByCluster: async (context, { organizationId, clusterId }) => (await db
      .collection('buys').where('organizationId', '==', organizationId).where('clusterId', '==', clusterId).where('payment.status', 'in', ['complete']).limit(1).get()).docs.length,
    hasPaymentsCompleteByProject: async (context, { organizationId, projectId }) => (await db
      .collection('buys').where('organizationId', '==', organizationId).where('projectId', '==', projectId).where('payment.status', 'in', ['complete']).limit(1).get()).docs.length,
    hasPaymentsCompleteByVendor: async (context, { vendorId }) => (await db
      .collection('buys').where('vendorId', '==', vendorId).where('payment.status', 'in', ['complete']).limit(1).get()).docs.length,

    async updateNotes({ rootGetters }, { buyId, comments }) {
      const user = rootGetters['user/data']
      const createdAt = Timestamp.now()
      const dataToUpdate = { [`notes.${createdAt.toDate().getTime()}`]: { createdAt, userId: user.id, userName: `${user.firstName} ${user.lastName}`, comments } }

      await db.collection('buys').doc(buyId).update(dataToUpdate)
    },

    async refund(context, { buyId, payerId, payerEmail, name, amountText, data }) {
      const lang = context.rootState.project?.dbData?.language ?? 'en'
      await callBackend('stripe/create-refund', { buyId, ...data })
      if (payerEmail) await callBackend('emails/payment-refund', { to: payerEmail, lang, params: { amount: amountText, name, buyId } })
      await callBackend('notifications/push/create', {
        scope: 'user',
        context: { userId: payerId },
        language: lang,
        data: {
          translation: { id: 'paymentRefund', params: { amount: amountText, name } },
          linkType: null,
        },
      })
    },

    reduce: async (context, { buyId, payerId, payerEmail, name, data }) => {
      const { amount, userId, userName, comments } = data

      const buy = context.rootGetters['organization/buys'].find(b => b.id === buyId)
      const createdAt = Timestamp.now()
      const newAmount = parseFloat((buy.payment.amount - amount).toFixed(2))
      const dataToUpdate = { 'payment.amount': FieldValue.increment(amount * -1), [`payment.reductions.${createdAt.toDate().getTime()}`]: { createdAt, amount, userId, userName, comments } }

      await db.collection('orders').doc(buy.orderId).update(dataToUpdate)
      await db.collection('buys').doc(buyId).update(dataToUpdate)

      const lang = context.rootState.project.dbData.language ?? 'en'
      const amountText = getAmountTextFormatCurrency(newAmount, buy.payment.currency)

      await callBackend('emails/payment-reduction', { to: payerEmail, lang, params: { amount: amountText, name } })

      await callBackend('notifications/push/create', {
        scope: 'user',
        context: { userId: payerId },
        language: lang,
        data: {
          translation: { id: 'paymentReduction', params: { amount: amountText, name } },
          linkType: null,
        },
      })
    },

    async deleteReduction(context, { buyId, createdAt }) {
      const buy = context.rootGetters['organization/buys'].find(b => b.id === buyId)
      const amount = Object.values(buy.payment.reductions).find(item => item.createdAt === createdAt).amount
      const reductions = buy.payment.reductions
      delete reductions[createdAt.toDate().getTime()]
      const dataToUpdate = { 'payment.amount': parseFloat((buy.payment.amount + amount).toFixed(2)), 'payment.reductions': reductions }

      try {
        await db.runTransaction(async transaction => {
          const orderRef = db.collection('orders').doc(buy.orderId)
          transaction.update(orderRef, dataToUpdate)
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
      }

      try {
        await db.runTransaction(async transaction => {
          const buyRef = db.collection('buys').doc(buyId)
          transaction.update(buyRef, dataToUpdate)
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
      }
    },

    async updateDueDate(context, { buyId, payerId, payerEmail, name, amountText, data }) {
      const { dueDate, sendNotification } = data

      const buy = context.rootGetters['organization/buys'].find(b => b.id === buyId)
      const dataToUpdate = { 'payment.dueDate': dueDate }
      await db.collection('orders').doc(buy.orderId).update(dataToUpdate)

      if (sendNotification) {
        const lang = context.rootState.project.dbData.language ?? 'en'
        const projectName = context.rootState.project.dbData.name
        const date = new Date(data.dueDate.seconds * 1000).toLocaleDateString()
        await callBackend('emails/payment-edit-due-date', { to: payerEmail, lang, params: { amount: amountText, name, projectName, date } })

        await callBackend('notifications/push/create', {
          scope: 'user',
          context: { userId: payerId },
          language: lang,
          data: {
            translation: { id: 'paymentEditDueDate', params: { amount: amountText, name, projectName, date } },
            linkType: null,
          },
        })
      }
    },

    async sendReminderPayment(context, { payerId, payerEmail, amountText }) {
      const lang = context.rootState.project.dbData.language ?? 'en'
      const projectName = context.rootState.project.dbData.name
      const organizationId = context.rootState.organization.dbData.id
      await callBackend('emails/payment-send-reminder', { to: payerEmail, lang, params: { amount: amountText, projectName, organizationId } })

      await callBackend('notifications/push/create', {
        scope: 'user',
        context: { userId: payerId },
        language: lang,
        data: {
          translation: { id: 'paymentSendReminder', params: { amount: amountText, projectName } },
          linkType: 'pendingPayments',
        },
      })
    },

    /*
    * Stripe connected accounts
    */
    readStripeAccountInfo: async ({ state }, { accountId, currency }) => {
      try {
        const stripeAccount = await callBackend('stripe/accounts/read', { accountId, currency })
        return {
          // Stripe data
          ...stripeAccount,
          // Custom properties
          name: stripeAccount.email || stripeAccount.business_profile.url || stripeAccount.business_profile.name || i18n.t('filters.incomplete'),
          get status() {
            if (!stripeAccount.details_submitted) return 'incomplete'
            if (!stripeAccount.charges_enabled) return 'paymentsDisabled'
            if (!stripeAccount.payouts_enabled) return 'payoutsDisabled'
            return 'complete'
          },
        }
      } catch (err) { return null }
    },
    getStripeAccountOnboardingLink: (context, { accountId, currency }) => callBackend('stripe/accounts/get-onboarding-link', { accountId, currency }),
  },
}
