import { firestoreAction } from 'vuexfire'
import { db, uploadFileAndGetUrl, uploadFileAndGetObj } from '@/services/firebase'
import imagesExtensionsEnum from '@/enums/imagesExtensionsEnum'

export default {
  namespaced: true,
  state: () => ({
    dbDataUser: null,
    dbDataClub: null,
    dbDataFilled: true,
    currentEntity: null,
    hasDefaultElement: true,
  }),
  getters: {
    dataUser: state => ({ ...state.dbDataUser }),
    dataClub: state => ({ ...state.dbDataClub }),
    allFieldsFilledOut: state => state.dbDataFilled,
    currentEntity: state => state.currentEntity,
    hasDefaultElement: state => state.hasDefaultElement,
    keyDinamicData: state => state.currentEntity === 'club' ? 'dbDataClub' : 'dbDataUser',
    keyDinamicFields: state => state.currentEntity === 'club' ? 'dynamicFields' : 'fields',
  },
  mutations: {
    deleteField(state, payload) {
      const keyDinamicData = state.currentEntity === 'club' ? 'dbDataClub' : 'dbDataUser'
      const keyDinamicFields = state.currentEntity === 'club' ? 'dynamicFields' : 'fields'
      state[keyDinamicData][keyDinamicFields] = state[keyDinamicData][keyDinamicFields].filter(field => field?.id !== payload)
    },
  },
  actions: {
    bindDataUser: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbDataUser',
      db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('enrollment'),
    )),
    bindDataClub: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbDataClub',
      db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('clubEnrollment'),
    )),
    async readForm(context, { path, doc }) {
      return (await db.collection(path).doc(doc).get()).data()
    },
    setCurrentEntity(context, currentEntity) {
      context.state.currentEntity = currentEntity
    },
    setHasDefaultElement(context, hasDefaultElement) {
      context.state.hasDefaultElement = hasDefaultElement
    },
    removeParentData(context) {
      if (context.state.dbDataUser?.fields?.length) context.state.dbDataUser = { ...context.state.dbDataUser, fields: context.state.dbDataUser?.fields?.filter(f => !f.requestedOnceForAllChildren) ?? [] }
    },
    updateField(context, payload) {
      const keyDinamicData = context.state.currentEntity === 'club' ? 'dbDataClub' : 'dbDataUser'
      const keyDinamicFields = context.state.currentEntity === 'club' ? 'dynamicFields' : 'fields'
      const existsAtIndex = context.state[keyDinamicData]?.[keyDinamicFields]?.findIndex(field => field?.id === payload?.id)

      if (payload?.id && existsAtIndex !== -1) {
        context.state[keyDinamicData][keyDinamicFields][existsAtIndex] = { ...context.state[keyDinamicData][keyDinamicFields][existsAtIndex], ...payload }
      } else {
        // generate unique firebase id
        const collectionRef = db.collection('forms').doc()
        const newField = {
          ...payload,
          id: collectionRef.id,
        }

        if (context.state[keyDinamicData]?.[keyDinamicFields]) {
          context.state[keyDinamicData][keyDinamicFields].push(newField)
        } else {
          context.state[keyDinamicData] = { ...context.state[keyDinamicData], [keyDinamicFields]: [newField] }
        }
      }
    },
    changeFilledState(context, payload) {
      context.state.dbDataFilled = payload
    },
    async getFormValues({ getters }, { form, typeUser = null }) {
      const fields = getters.currentEntity === 'club' ? getters.dataClub.dynamicFields : getters.dataUser.fields
      return fields ? fields?.filter(f => getters.currentEntity === 'user' ? (typeUser === 'parent' ? f.requestedOnceForAllChildren : !f.requestedOnceForAllChildren) : true)
        .map(formValue => {
          const field = Object.entries(form).find(([key]) => key === formValue?.id)
          return formValue?.label && ({
            id: formValue?.id,
            value: field ? field[1] : null,
          })
        })?.filter(e => e) : []
    },
    async parseForm(context, { form, storagePath }) {
      return Object.fromEntries(await Promise.all(Object.entries(form)
        .map(async ([key, value]) => {
          if (typeof value === 'object' && value instanceof File) {
            return imagesExtensionsEnum.includes(value.name?.split('.').pop())
              ? [key, await uploadFileAndGetUrl(storagePath, value)]
              : [key, await uploadFileAndGetObj(storagePath, value)]
          }
          return [key, value]
        })))
    },
    async updateForm({ state, getters }, { path, doc, data }) {
      const formRef = db.collection(path).doc(doc)
      const { dynamicFields } = getters.dataClub
      const { fields } = getters.dataUser
      const elements = getters.currentEntity === 'club' ? dynamicFields : fields
      const parsedData = elements?.filter(field => field.isInfoOnly ? field.text : field.label)
        ?.map(field => field?.options ? ({ ...field, options: field.options.filter(option => option?.label) }) : field) ?? null
      if (data.logo) data.logo = await uploadFileAndGetUrl(path, data.logo)
      const dataToUpdate = {
        ...((getters.currentEntity === 'club' && parsedData) && { dynamicFields: parsedData }),
        ...(getters.currentEntity === 'user' && { fields: parsedData }),
        ...(data && { ...data }),
      }
      state[getters.keyDinamicData] = dataToUpdate
      await formRef.set(dataToUpdate)
    },
    async deletePage({ state, getters }, { organizationId, projectId, pageId }) {
      const fieldsData = state[getters.keyDinamicData][getters.keyDinamicFields]
        .filter(field => field.page !== pageId)
        .map(field => field.page > pageId ? ({ ...field, page: field.page - 1 }) : field)
      // update local state
      state[getters.keyDinamicData][getters.keyDinamicFields] = [...fieldsData]
      // update ddbb
      const docStore = getters.currentEntity === 'club' ? 'clubEnrollment' : 'enrollment'
      const formRef = db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc(docStore)
      await formRef.set({ fields: fieldsData }, { merge: true })
    },
  },
}
