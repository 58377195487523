import http from 'axios'
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import firebaseConfig from '@/config/firebase'

firebase.initializeApp(firebaseConfig)

export const functions = firebase.app().functions('europe-west1')
export const db = firebase.firestore()
export const storage = firebase.storage()
export const waiverStorage = firebase.app().storage('gs://owqlo-waivers-production-id')
export const auth = firebase.auth()
export const { Timestamp, FieldValue } = firebase.firestore // eslint-disable-line prefer-destructuring
export const { GeoPoint } = firebase.firestore // eslint-disable-line prefer-destructuring

const backendUrl = window.isFutbolistasOn ? 'https://futbolistas-on.ew.r.appspot.com' : `https://${window.isDev ? 'staging-dot-owqlo-master-production-id' : 'owqlo-master-production-id'}.ew.r.appspot.com`
// const backendUrl = 'http://localhost:8081'

export const callBackend = async (endpoint, data) => {
  const idToken = await firebase.auth().currentUser?.getIdToken()
  const headers = { Authorization: `Bearer ${idToken}` }
  const res = await http.post(`${backendUrl}/${endpoint}`, data, { headers })
  return res.data
}

export const runCloudFunction = (functionName, data) => http.post(
  `https://europe-west1-owqlo-master-production-id.cloudfunctions.net/${functionName}`,
  { data },
)
export const getStorageFileUrl = storagePath => firebase.storage().ref(storagePath).getDownloadURL()
export const getWaiverUrl = storagePath => waiverStorage.ref(storagePath).getDownloadURL()
export const uploadWaiverFile = (storagePath, file) => waiverStorage.ref(storagePath).put(file)
export const uploadFileAndGetUrl = async (storagePath, file, diff = '') => { // For images
  if (!(file instanceof File)) return file // this happens when the file has been readed from the database, so it is just an URL, or when the file has been deleted an is null
  const fileSnap = await firebase.storage().ref(`${storagePath}/${file.name + diff}`).put(file) // when two images with the same name are saved, the second will overwrite the first. Conditional param 'diff' avoids that
  return fileSnap.ref.getDownloadURL()
}

export const uploadFileAndGetObj = async (storagePath, file) => { // For non-images
  if (!(file instanceof File)) return file // this happens when the file has been readed from the database, so it is a simple object
  const fileSnap = await firebase.storage().ref(`${storagePath}/${file.name}`).put(file)
  const url = await fileSnap.ref.getDownloadURL()
  return {
    publicUrl: url,
    name: file.name,
    type: file.type,
    sizeInBytes: file.size,
    storagePath: `${storagePath}/`,
  }
}

export const deleteFileAndReturnSuccess = async path => {
  let success
  const fileRef = firebase.storage().refFromURL(path)
  await fileRef
    .delete()
    .then(() => { success = true })
    .catch(() => { success = false })
  return success
}

export const allCombinations = params => {
  const args = Object
    .values(params)
    .map(arg => !arg.length ? [''] : arg)
  const r = []
  const max = args.length - 1
  const helper = (arr, i) => {
    args[i].forEach((arg, j) => i === max ? r.push(arr.concat(args[i][j])) : helper(arr.concat(args[i][j]), i + 1))
  }
  helper([], 0)
  return r
}
