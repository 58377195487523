import i18n from '@/plugins/i18n'
import store from '@/store'
import clubHotelsFormRoutes from '@/modules/clubHotelsForm/router'

export default [
  {
    path: '/organization/:organizationId/project/:projectId/club/:clubId',
    name: 'club',
    component: () => import('@/modules/club/Club'),
    redirect: { name: 'club-edit' },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, clubId } = to.params
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        await store.dispatch('organization/bind', organizationId)
      }
      if (store.state.project.dbData?.id !== projectId) {
        await store.dispatch('project/bind', { organizationId, projectId })
        await store.dispatch('project/bindServices', { organizationId, projectId })
        await store.dispatch('dynamicForm/bindDataClub', { organizationId, projectId })
      }
      if (store.state.club.dbData?.id !== clubId) {
        const entity = await store.dispatch('club/bind', { organizationId, projectId, clubId })
        if (!entity) next({ name: '404' })
        await store.dispatch('club/bindTeams', { clubId, projectId })
        await store.dispatch('club/bindMembers', { organizationId, projectId, clubId })
      }
      if (!store.state.club.dbTeams.length) await store.dispatch('club/bindTeams', { clubId, projectId })
      store.commit('loader/hide')

      // Security
      if (!store.state.organization.dbData) await store.dispatch('organization/bind', organizationId)
      if (!store.state.project.dbData) await store.dispatch('project/bind', { organizationId, projectId })
      if (!store.state.club.dbData) await store.dispatch('club/bind', { organizationId, projectId, clubId })
      const isUserOrganizationAdmin = store.getters['user/adminOrganizationIds'].includes(to.params.organizationId)
      const isUserProjectCollab = store.getters['user/staffProjectIds'].includes(to.params.projectId)
      const isUserclubCollab = store.getters['user/collabClubIds'].includes(to.params.clubId)
      if (!(isUserOrganizationAdmin || isUserProjectCollab || isUserclubCollab || store.getters['superadmin/isUserSuperadmin'])) next({ name: '404' })
      else next()
    },
    children: [
      {
        path: 'settings',
        name: 'club-edit',
        component: () => import('@/modules/club/EditClub'),
        meta: {
          title: () => `${store.getters['club/data']?.name} - ${i18n.t('club.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.settings'),
        },
        props: true,
      },
      {
        path: 'staff',
        name: 'club-staff',
        component: () => import('@/modules/club/StaffClub'),
        meta: {
          title: () => `${store.getters['club/data']?.name} - ${i18n.t('club.sections.staff')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.staff'),
        },
        props: true,
      },
      {
        path: 'cohort/:cohortId/team/:teamId',
        name: 'club-team',
        component: () => import('@/modules/club/Team'),
        meta: {
          title: () => `${store.getters['club/data']?.name} - ${i18n.t('club.sections.team')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.teamDetail'),
        },
        props: true,
      },
      {
        path: 'trips/',
        name: 'club-tripService',
        component: () => import('@/modules/club/TripService'),
        meta: {
          title: () => `${i18n.t('club.sections.trips')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.trips'),
        },
        props: true,
      },
      {
        path: 'log',
        name: 'club-eventLog',
        component: () => import('@/components/EventLog'),
        meta: {
          title: () => `${store.getters['club/data'].name} - ${i18n.t('club.sections.eventLog')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.eventLog'),
        },
        props: true,
      },
      {
        path: 'matches/by-club',
        name: 'club-matches-by-club',
        component: () => import('@/modules/competition/matches/MatchList'),
        meta: {
          title: () => `${store.getters['club/data']?.name} - ${i18n.t('competition.sections.matchesByClub')} - OWQLO`,
          headerTitle: () => i18n.t('competition.sections.cohortsMatches'),
        },
        props: true,
        beforeEnter: async (to, from, next) => {
          const { organizationId, projectId, clubId } = to.params
          if (!store.state.competition.dbClubs.length) await store.dispatch('competition/bindClubs', { organizationId, projectId })
          if (store.getters['competition/currentMatchesEntity'].type !== 'club' || store.getters['competition/currentMatchesEntity'].id !== clubId) {
            store.commit('loader/show')
            await store.dispatch('competition/bindClubMatches', { projectId, clubId })
            await store.dispatch('competition/readTeamsRoundsGroupsForMatches', { organizationId, projectId })
            // store.getters['club/teams'].find(t => t.id === teamId) // Dont download if is already downloaded
            // const clubsToDownload = store.getters['competition/matches'].flatMap(({ cohortId, clubIds }) => clubIds.map(clubId => ({ cohortId, clubId })))
            //   .filter((v, i, a) => a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.clubId === v.clubId)) === i)
            //   .filter(({ clubId, cohortId }) => !store.getters['competition/clubs'].find(c => c.cohortId === cohortId && c.id === clubId)
            store.commit('loader/hide')
            store.state.competition.currentMatchesEntity = { type: 'club', id: clubId }
          }
          next()
        },
      },
      {
        path: 'matches/by-cohort/:cohortId/:matchId/edit',
        name: 'club-match-edit',
        component: () => import('@/modules/competition/matches/EditMatch'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('common.match')} - OWQLO`,
          headerTitle: () => i18n.t('common.match'),
        },
        props: true,
        beforeEnter: async (to, from, next) => {
          const { organizationId, projectId, cohortId, matchId } = to.params
          const match = store.getters['competition/matches'].find(m => m.id === matchId && m.cohortId === cohortId)
          if (!match) {
            store.state.competition.dbMatches = [await store.dispatch('competition/readMatch', { organizationId, projectId, cohortId, matchId })]
            await store.dispatch('competition/readTeamsRoundsGroupsForMatches', { organizationId, projectId })
          }
          next()
        },
      },
      ...clubHotelsFormRoutes,
    ],
  },
]
