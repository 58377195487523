import i18n from '@/plugins/i18n'
import store from '@/store'
import orderRoutes from '@/modules/orders/vendorRouter'

export default [
  {
    path: '/vendor/:vendorId',
    name: 'vendor',
    component: () => import('@/modules/vendors/views/VendorMainView.vue'),
    redirect: { name: 'vendor-managers' },
    beforeEnter: async (to, from, next) => {
      const { vendorId } = to.params
      store.commit('loader/show')
      if (!store.state.superadmin.dbOrganizations.length) await store.dispatch('superadmin/bindOrganizations')
      if (store.state.vendors.dbVendor?.id !== vendorId) {
        const vendor = await store.dispatch('vendors/bind', { vendorId })
        if (!vendor) next({ name: '404' })
        await store.dispatch('vendors/unbindProducts')
        await store.dispatch('vendors/unbindVendors')
        await store.dispatch('orders/unbindVendorProductBuys')
        await store.dispatch('orders/unbindOrganizationProductBuys')
        await store.dispatch('vendors/bindManagers', { vendorId })
        await store.dispatch('vendors/bindProducts', { vendorId })
      } else {
        if (!store.state.vendors?.dbManagers?.length) await store.dispatch('vendors/bindManagers', { vendorId })
        if (!store.state.vendors.dbProducts.length) await store.dispatch('vendors/bindProducts', { vendorId })
      }
      store.commit('loader/hide')
      // Security
      if (!store.getters['vendors/isUserVendorManager']) next({ name: '404' })
      else next()
    },
    children: [
      {
        path: 'managers',
        name: 'vendor-managers',
        component: () => import('@/modules/vendors/views/ManagersListView.vue'),
        meta: {
          title: () => `${i18n.t('cluster.sections.managers')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.managers'),
        },
        props: true,
      },
      {
        path: 'payments-settings',
        name: 'vendor-payments-settings',
        component: () => import('@/modules/vendors/views/EditVendorView.vue'),
        meta: {
          title: () => `${store.getters['vendors/vendor']?.name} - ${i18n.t('project.sections.payments')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.payments'),
        },
        redirect: { name: 'vendor-payments-general-settings' },
        props: true,
        children: [
          {
            path: 'general',
            name: 'vendor-payments-general-settings',
            component: () => import('@/modules/vendors/views/editVendor/SettingsView.vue'),
            meta: {
              title: () => `${store.getters['vendors/vendor']?.name} - ${i18n.t('project.sections.payments')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.payments'),
              viewEntityType: 'vendor',
            },
            props: true,
          },
          {
            path: 'partners',
            name: 'vendor-payments-settings-partners',
            component: () => import('@/modules/vendors/views/editVendor/PaymentsSettingsPartners.vue'),
            meta: {
              title: () => `${store.getters['vendors/vendor']?.name} - ${i18n.t('project.sections.payments')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.payments'),
              viewEntityType: 'vendor',
            },
            props: true,
          },
        ],
      },
      {
        path: 'products',
        name: 'vendor-products',
        component: () => import('@/modules/products/views/ProductListView.vue'),
        meta: {
          title: () => `${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      {
        path: 'product/create',
        name: 'vendor-product-create',
        component: () => import('@/modules/products/views/ProductForm.vue'),
        meta: {
          title: () => `${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      {
        path: 'product/edit/:archetypeId',
        name: 'vendor-product-edit',
        component: () => import('@/modules/products/views/ProductForm.vue'),
        meta: {
          title: () => `${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      ...orderRoutes,
    ],
  },
]
