import { db } from '@/services/firebase'

export const simplifyStr = str => str.trim().toLowerCase().replaceAll(/[^a-z0-9_ ]/g, '').replace(/ +/g, ' ').split(' ').join('_')

export const normalizeString = str => str
  .normalize('NFD') // Normalizar caracteres a su forma descompuesta
  .replace(/\p{Diacritic}/gu, '') // Eliminar acentos
  .toLowerCase() // Convertir a minúsculas
  .replace(/[^a-z0-9]/g, '') // Eliminar caracteres especiales

const addRandomIdToStr = str => `${str}${str ? '__' : ''}${Math.random().toString(36).slice(2, 6)}`

export const strToId = str => simplifyStr(str)?.length ? simplifyStr(str) : addRandomIdToStr(simplifyStr(str))

export const strToUniqueId = str => addRandomIdToStr(simplifyStr(str))

export const getUniqueId = () => Math.random().toString(36).slice(2, 10)

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

const docExists = async (path, id) => (await db.collection(path).doc(id).get()).data()
const archetypeExists = async (path, archetypeId) => (await db.collection(path).where('archetypeId', '==', archetypeId).limit(1).get()).docs.length

export const strToIdNoRepeatInAllDb = async (str, path) => {
  const idStr = simplifyStr(str)
  const getUniqueIdAllPaths = async idStr2 => {
    if (await docExists(path, idStr2)) return getUniqueId(`${idStr}__${addRandomIdToStr()}`)
    return idStr2
  }
  return getUniqueIdAllPaths(idStr)
}

export const strToArchetypeIdNoRepeatInAllDb = async (str, path) => {
  const idStr = simplifyStr(str)
  const getUniqueArchetypeIdAllPaths = async idStr2 => {
    // if (await docExists(path, idStr2)) return strToUniqueId(idStr2)
    if (await archetypeExists(path, idStr2)) return strToUniqueId(idStr2)
    return idStr2
  }
  return getUniqueArchetypeIdAllPaths(idStr)
}

export const getTruncatedStringFromArray = strings => {
  if (!strings.length) return ''
  if (strings.length === 1) return strings[0]
  return `${strings[0]} (+${strings.length - 1})`
}
