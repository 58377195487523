export default {
  namespaced: true,
  state: () => ({
    type: null,
    show: false,
    showMultipleAlerts: false,
    text: null,
    color: null,
    iconColor: null,
    icon: null,
    multipleAlerts: [],
    config: {
      success: {
        color: '#dbefdc',
        iconColor: '#89D184',
        icon: 'check_circle',
      },
      warning: {
        color: '#fef3d3',
        iconColor: '#f8c125',
        icon: 'warning',
      },
      error: {
        color: '#df5555',
        iconColor: 'white',
        icon: 'error_outline',
      },
    },
  }),
  mutations: {
    alertSuccess(state, text) {
      state.type = 'success'
      state.text = text
      state.show = true
    },
    alertWarning(state, text) {
      state.type = 'warning'
      state.text = text
      state.show = true
    },
    alertError(state, text) {
      state.type = 'error'
      state.text = text
      state.show = true
    },
    closeAlertBox(state) {
      state.show = false
    },
    setShowMultipleAlerts(state) {
      state.showMultipleAlerts = true
    },
    setMultipleAlerts(state, alerts) {
      state.multipleAlerts = alerts
    },
    closeMultipleAlerts(state) {
      state.showMultipleAlerts = false
    },
  },
  actions: {
    openAlertBox({ commit }, [alertType, data]) {
      if ([
        'alertSuccess',
        'alertWarning',
        'alertError',
      ].includes(alertType)) {
        commit(alertType, data)
      }
      setTimeout(() => {
        commit('closeAlertBox')
      }, 5000)
    },
    openAlertBoxMultiple({ commit }, alerts) {
      commit('setShowMultipleAlerts')
      commit('setMultipleAlerts', alerts)
      setTimeout(() => {
        commit('closeMultipleAlerts')
      }, 5000)
    },
  },
}
