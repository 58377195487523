import i18n from '@/plugins/i18n'
import store from '@/store'


export default [
  {
    path: 'vendors',
    name: 'organization-vendors',
    component: () => import('@/modules/vendors/views/VendorsListView.vue'),
    meta: {
      title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.vendors'),
    },
    props: true,
  },
  {
    path: 'vendors/create',
    name: 'organization-vendors-create',
    component: () => import('@/modules/vendors/views/EditVendorView.vue'),
    meta: {
      title: () => `${store.getters['organization/data']?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.vendors'),
    },
    redirect: { name: 'organization-vendors-create-settings' },
    props: true,
    children: [
      {
        path: 'settings',
        name: 'organization-vendors-create-settings',
        component: () => import('@/modules/vendors/views/editVendor/SettingsView.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.vendors'),
          viewEntityType: 'organization',
        },
        props: true,
      },
    ],
  },
  {
    path: 'vendors/edit/:vendorId',
    name: 'organization-vendors-edit',
    component: () => import('@/modules/vendors/views/EditVendorView.vue'),
    meta: {
      title: () => `${store.getters['organization/data']?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.vendors'),
    },
    redirect: { name: 'organization-vendors-edit-settings' },
    props: true,
    children: [
      {
        path: 'settings',
        name: 'organization-vendors-edit-settings',
        component: () => import('@/modules/vendors/views/editVendor/SettingsView.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.vendors'),
          viewEntityType: 'organization',
        },
        props: true,
      },
    ],
  },
]
