import { db, FieldValue, uploadFileAndGetUrl, callBackend } from '@/services/firebase'
import guildFormData from '@/modules/guild/guildFormData'

export default {
  namespaced: true,
  actions: {
    getBenefitRequests: ({ rootState }, { organizationId, clusterId, ids }) => callBackend('exports/guild/get-benefit-requests', { organizationId, lang: rootState.locale, clusterId, ids }),
    // Create
    async create(context, { organizationId, clusterId, data }) {
      const guildRef = db.collection(`properties/${organizationId}/projects`).doc()
      const id = guildRef.id
      const guild = {
        ...data,
        id,
        ...(clusterId && { clusterId }),
        active: true,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await guildRef.set(guild)
      const enrollmentRef = db.collection(`properties/${organizationId}/projects/${id}/forms`).doc('enrollment')
      await enrollmentRef.set(guildFormData)
      const rolePresets = context.rootGetters['organization/rolePresets']
      rolePresets
        .filter(preset => !preset.projectTypes || preset.projectTypes.includes(guild.type))
        .map(preset => db.collection(`properties/${organizationId}/projects/${id}/roles`).doc(preset.id)
          .set((({ entityType, projectTypes, ...rest }) => ({ ...rest }))(preset)))
      return id
    },
    // Update
    async update(context, { organizationId, projectId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)

      const ads = data.ads ? {
        link: data.ads.link ?? null,
        full: await uploadFileAndGetUrl(storagePath, data.ads.full),
        footer: await uploadFileAndGetUrl(storagePath, data.ads.footer),
      } : null
      const dataToUpdate = { ...data, ...(ads && { ads }), logo, banner }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
      return true
    },
  },
}
