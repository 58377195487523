import { firestoreAction } from 'vuexfire'
import { db, uploadFileAndGetUrl, FieldValue } from '@/services/firebase'
import { strToArchetypeIdNoRepeatInAllDb } from '@/utils/formatters'
import getProductStatus from '@/utils/getProductStatus'

export default {
  namespaced: true,
  state: () => ({
    dbTags: [],
    dbFamilies: [],
  }),
  getters: {
    tags: state => state.dbTags,
    families: state => state.dbFamilies.sort((a, b) => a.id.localeCompare(b.id)),
  },
  actions: {
    // Binds
    bindTags: firestoreAction(({ bindFirestoreRef }) => bindFirestoreRef('dbTags', db.collection('tags'))),
    bindFamilies: firestoreAction(({ bindFirestoreRef }) => bindFirestoreRef('dbFamilies', db.collection('families'))),

    // Create
    async createOreditProductArchetype(context, { data, isEdit }) {
      const storagePath = `product/${await strToArchetypeIdNoRepeatInAllDb(data.name, 'products')}`
      const images = (await Promise.all(data.images.map(async image => uploadFileAndGetUrl(storagePath, image)))).filter(image => image)
      if (data.variationsToDeleteBD.length) {
        await Promise.all(data.variationsToDeleteBD.map(async suffix => {
          const documentsRef = (await db.collection('products').where('suffix', '==', suffix).where('archetypeId', '==', data.archetypeId).get()).docs.map(s => s.ref)
          documentsRef[0].delete()
        }))
      }
      await Promise.all(data.products.map(async product => {
        const suffixFormated = data.products.every(p => p?.suffix) || false ? product.suffix : null
        const id = suffixFormated ? await strToArchetypeIdNoRepeatInAllDb(`${data.archetypeId}-${suffixFormated}`, 'products') : data.archetypeId
        const productData = (({ suffix, vendorProductId, index, tags, ...rest }) => ({ ...rest, ...(tags && { tags }), ...(vendorProductId && { vendorProductId }) }))(product)
        const allTags = productData?.tags || data?.tags ? { ...productData.tags, ...data.tags } : {}
        const dataToSave = (({ products, variationsToDeleteBD, ...rest }) => ({
          ...rest,
          ...productData,
          ...(!isEdit && { id }),
          ...(!isEdit && { createdAt: FieldValue.serverTimestamp() }),
          ...(isEdit && { updatedAt: FieldValue.serverTimestamp() }),
          ...(suffixFormated && { suffix: suffixFormated }),
          ...(allTags && Object.values(allTags).length && { tags: allTags }),
          images,
        }))(data)
        if (product.suffix && isEdit) {
          const documentsRef = (await db.collection('products').where('suffix', '==', product.suffix).where('archetypeId', '==', data.archetypeId).get())
            .docs.map(s => s.ref)
          if (documentsRef.length) await documentsRef[0].update({ ...dataToSave, updatedAt: FieldValue.serverTimestamp() })
          else await db.collection('products').doc(id).set({ ...dataToSave, id, createdAt: FieldValue.serverTimestamp() })
        } else {
          await db.collection('products').doc(id).set({ ...dataToSave, id })
        }
      }))
    },

    // Update
    async updateProductCompIdsForShop(context, { archetypeId, compIdsForShop }) {
      const productArchetypeData = await db.collection('products').where('archetypeId', '==', archetypeId).get()

      await Promise.all(productArchetypeData.docs.map(async snap => {
        await db.collection('products').doc(snap.data().id).update({ compIdsForShop })
      }))
    },
    async updateProductCompProjectIdsForEnrollment(context, { archetypeId, compProjectIdsForEnrollment }) {
      const productArchetypeData = await db.collection('products').where('archetypeId', '==', archetypeId).get()

      await Promise.all(productArchetypeData.docs.map(async snap => {
        await db.collection('products').doc(snap.data().id).update({ compProjectIdsForEnrollment })
      }))
    },

    // Delete
    delete: (context, { productId }) => db.collection('products').doc(productId).delete(),

    // Others
    async getProduct(context, { productId }) { return (await db.collection('products').doc(productId).get()).data() },
    async archive(context, { archetypeId }) {
      const productArchetypeData = await db.collection('products').where('archetypeId', '==', archetypeId).get()

      await Promise.all(productArchetypeData.docs.map(async snap => {
        await db.collection('products').doc(snap.data().id).update({ isActive: false, isPublished: false })
      }))
    },
    async unarchive(context, { archetypeId }) {
      const productArchetypeData = await db.collection('products').where('archetypeId', '==', archetypeId).get()

      await Promise.all(productArchetypeData.docs.map(async snap => {
        await db.collection('products').doc(snap.data().id).update({ isActive: true, isPublished: false })
      }))
    },
    async deleteShippingRateId(context, { shippingRateId }) {
      const products = context.rootGetters['organization/products'].filter(oP => oP.shippingRateIds?.includes(shippingRateId))

      await Promise.all(products.map(async product => {
        let dataToUpdate = { shippingRateIds: FieldValue.arrayRemove(shippingRateId) }
        const productStatus = getProductStatus(product)
        if (product.shippingRateIds.length === 1) dataToUpdate = { ...dataToUpdate, ...(productStatus === 'active' && { isPublished: false }) }
        await db.collection('products').doc(product.id).update(dataToUpdate)
      }))
    },
  },
}
