<template>
  <v-layout justify-center align-center>
    <v-snackbar
      :value="alert.show"
      class="app-snackbar"
      top
      center
      elevation="0"
      min-width="64vw"
      :color="alert.config[alert.type]?.color"
      :content-class="`app-snackbar__${alert.type}`"
    >
      <span>
        <v-icon :color="alert.config[alert.type]?.iconColor" class="mr-2"> {{ alert.config[alert.type]?.icon }} </v-icon>
        <span :class="`app-snackbar__${alert.type}`" v-html="alert.text " />
      </span>
    </v-snackbar>

    <v-snackbar
      v-for="(element,index) in alert.multipleAlerts" :key="index"
      :value="alert.showMultipleAlerts" class="app-snackbar"
      :style="{'margin-top':calcMargin(index)}"
      top center min-width="64vw" :color="alert.config[element.type].color"
      :content-class="`app-snackbar__${element.type}`"
    >
      <div class="d-flex">
        <v-icon :color="alert.config[element.type].iconColor" class="mr-2">{{ alert.config[element.type].icon }}</v-icon>
        <span :class="`app-snackbar__${element.type}`" v-text="element.text" />
      </div>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Alert',
  computed: {
    ...mapState(['alert']),
  },
  methods: {
    calcMargin(i) {
      return `${i * 60}px`
    },
  },
}
</script>

<style lang="scss">
.v-snack__content {
  margin: auto !important;
  text-align: center !important;
}

.app-snackbar {
  &__error {
    color: white;
    background-color: #df5555;
  }

  &__success {
    color: black;
    background-color: #dbefdc;
  }

  &__warning {
    color: black;
    background-color: #fef3d3;
  }
}

</style>
