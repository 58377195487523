export default {
  profileFields: [
    { isRequired: true, id: 'firstName' },
    { isRequired: true, id: 'lastName' },
    { isRequired: false, id: 'email' },
    { isRequired: true, id: 'phone' },
  ],
  fields: [
    {
      isStatic: true,
      isInfoOnly: true,
      id: 'title',
      page: 1,
      text: '¿Qué relación tiene con el club?',
      type: 'info-title',
    },
    {
      isRequired: false,
      isStatic: true,
      isSwitch: true,
      id: 'sympathizer',
      label: 'Número de simpatizante',
      page: 1,
      type: 'text',
      switchText: 'Soy simpatizante',
    },
    {
      isRequired: false,
      isStatic: true,
      isSwitch: true,
      id: 'subscriberCode',
      label: 'Número de abonado',
      page: 1,
      type: 'text',
      switchText: 'Soy abonado',
    },
    {
      isRequired: false,
      isStatic: true,
      isSwitch: true,
      id: 'memberCode',
      label: 'Número de Socio',
      page: 1,
      type: 'text',
      switchText: 'Soy socio accionista',
    },
  ],
}
