import i18n from '@/plugins/i18n'

export default [
  {
    path: 'benefit-requests-form',
    name: 'guild-benefit-requests-form',
    component: () => import('@/modules/guildBenefitRequestsForm/MainView'),
    props: true,
    meta: {
      title: () => `${i18n.t('guild.sections.benefitRequest')} - OWQLO`,
      headerTitle: () => `${i18n.t('guild.sections.benefitRequest')}`,
    },
    redirect: { name: 'guild-benefit-requests-form-step-1' },
    children: [
      {
        path: 'step-1',
        name: 'guild-benefit-requests-form-step-1',
        component: () => import('@/modules/guildBenefitRequestsForm/steps/1GuildInfo.vue'),
        meta: {
          stepId: '1',
          title: () => `${i18n.t('guild.sections.benefitRequest')} - OWQLO`,
          headerTitle: () => `${i18n.t('guild.sections.benefitRequest')}`,
        },
        props: true,
      },
      {
        path: 'step-2',
        name: 'guild-benefit-requests-form-step-2',
        component: () => import('@/modules/guildBenefitRequestsForm/steps/2ProviderInfo.vue'),
        meta: {
          stepId: '2',
          title: () => `${i18n.t('guild.sections.benefitRequest')} - OWQLO`,
          headerTitle: () => `${i18n.t('guild.sections.benefitRequest')}`,
        },
        props: true,
      },
      {
        path: 'step-3',
        name: 'guild-benefit-requests-form-step-3',
        component: () => import('@/modules/guildBenefitRequestsForm/steps/3ContactPerson.vue'),
        meta: {
          stepId: '3',
          title: () => `${i18n.t('guild.sections.benefitRequest')} - OWQLO`,
          headerTitle: () => `${i18n.t('guild.sections.benefitRequest')}`,
        },
        props: true,
      },
      {
        path: 'step-4',
        name: 'guild-benefit-requests-form-step-4',
        component: () => import('@/modules/guildBenefitRequestsForm/steps/4Confirmation.vue'),
        meta: {
          stepId: '4',
          title: () => `${i18n.t('guild.sections.benefitRequest')} - OWQLO`,
          headerTitle: () => `${i18n.t('guild.sections.benefitRequest')}`,
        },
        props: true,
      },
    ],
  },
]
