import { firestoreAction } from 'vuexfire'
import { db, callBackend, uploadFileAndGetObj } from '@/services/firebase'
import store from '@/store'

export default {
  namespaced: true,
  state: () => ({
    dbGuildMatches: [],
    dbActivities: [],
    dbTicketEnrollments: [],
    dbUserTicketEnrollments: [],
    dbBuys: [],
  }),

  getters: {
    guildMatches: state => state.dbGuildMatches,
    activities: state => state.dbActivities,
    ticketEnrollments: state => state.dbTicketEnrollments,
    userTicketEnrollments: state => state.dbUserTicketEnrollments,
    payments: state => state.dbBuys.filter(b => b.type === 'ticket' && b.payment.status !== 'requested').map(({ payment, ...rest }) => ({ ...rest, buyId: rest.id, ...payment })),
  },

  actions: {
    // read
    bindBuysByGuildMatch: firestoreAction(async ({ bindFirestoreRef }, { guildMatchId }) => {
      store.commit('loader/show')
      bindFirestoreRef('dbBuys', db.collection('buys').where('guildMatchId', '==', guildMatchId))
      store.commit('loader/hide')
    }),
    bindGuildMatches: firestoreAction(({ bindFirestoreRef }, organizationId) => bindFirestoreRef(
      'dbGuildMatches',
      db.collection(`properties/${organizationId}/guildMatches`).where('date', '!=', null),
    )),
    bindActivities: firestoreAction(({ bindFirestoreRef }, { organizationId, guildMatchId }) => bindFirestoreRef(
      'dbActivities',
      db.collectionGroup('activities').where('organizationId', '==', organizationId).where('guildMatchId', '==', guildMatchId),
    )),
    bindTicketEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, activityId }) => bindFirestoreRef(
      'dbTicketEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/ticketEnrollments`).where('activityId', '==', activityId),
    )),
    bindUserTicketEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, userId }) => bindFirestoreRef(
      'dbUserTicketEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/ticketEnrollments`).where('userId', '==', userId),
    )),

    async assignTicketsToGuild(context, { organizationId, project, guildMatchId, ticketCount }) {
      await callBackend('projects/guild/assign-tickets-to-guild', { organizationId, projectId: project.id, guildMatchId, ticketCount })
    },

    // update
    async updateActivity({ state }, { organizationId, projectId, activityId, data }) {
      const ref = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId)
      const attachments = data.attachments ? await Promise.all(data.attachments.map(file => uploadFileAndGetObj(ref.path, file))) : []
      await ref.update({ ...data, attachments })
      return true
    },
    async updateGuildMatch({ state }, { organizationId, guildMatchId, data }) {
      await db.collection(`properties/${organizationId}/guildMatches`).doc(guildMatchId).update(data)
    },
    async approveTicketEnrollment({ rootState }, { organizationId, projectId, ticketEnrollmentId }) {
      await callBackend('projects/guild/approve-ticketEnrollment', { organizationId, projectId, ticketEnrollmentId })
    },
    async rejectTicketEnrollment({ rootState }, { organizationId, projectId, ticketEnrollmentId }) {
      await callBackend('projects/guild/reject-ticketEnrollment', { organizationId, projectId, ticketEnrollmentId })
    },
  },
}
