import i18n from '@/plugins/i18n'
import store from '@/store'

const childrenRoutesEvaluationsEdit = [
  {
    path: 'step-1',
    name: 'project-subscriber-medical-data-evaluations-create-step-1',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/1GeneralDataView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 1,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-2',
    name: 'project-subscriber-medical-data-evaluations-create-step-2',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/2PostureView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 2,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-3',
    name: 'project-subscriber-medical-data-evaluations-create-step-3',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/3StructureView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 3,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-4',
    name: 'project-subscriber-medical-data-evaluations-create-step-4',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/4RomView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 4,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-5',
    name: 'project-subscriber-medical-data-evaluations-create-step-5',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/5MotorPatternView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 5,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-6',
    name: 'project-subscriber-medical-data-evaluations-create-step-6',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/6StrengthView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 6,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-7',
    name: 'project-subscriber-medical-data-evaluations-create-step-7',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/7PowerView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 7,
      viewEntityType: 'project',
    },
    props: true,
  },
  {
    path: 'step-8',
    name: 'project-subscriber-medical-data-evaluations-create-step-8',
    component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/steps/8EvaluationView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
      stepId: 8,
      viewEntityType: 'project',
    },
    props: true,
  },
]

export default [
  {
    path: 'subscribers/:subscriberId',
    name: 'project-subscriber',
    component: () => import('@/modules/projectUsers/subscriber/views/SubscriberMainView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
    },
    redirect: { name: 'project-subscriber-general-information' },
    props: true,
    children: [
      {
        path: 'general-information',
        name: 'project-subscriber-general-information',
        component: () => import('@/modules/projectUsers/subscriber/views/GeneralInformationView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
          viewEntityType: 'project',
        },
        props: true,
      },
      {
        path: 'needs',
        name: 'project-subscriber-needs',
        component: () => import('@/modules/projectUsers/subscriber/views/NeedsView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
          viewEntityType: 'project',
        },
        props: true,
      },
      {
        path: 'payments',
        name: 'project-subscriber-payments',
        component: () => import('@/modules/projectUsers/subscriber/views/PaymentsView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
          viewEntityType: 'project',
        },
        props: true,
      },
      {
        path: 'medical-data',
        name: 'project-subscriber-medical-data',
        component: () => import('@/modules/projectUsers/subscriber/views/MedicalDataView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
          viewEntityType: 'project',
        },
        redirect: { name: 'project-subscriber-medical-data-stats' },
        props: true,
        children: [
          {
            path: 'stats',
            name: 'project-subscriber-medical-data-stats',
            component: () => import('@/modules/projectUsers/subscriber/views/medicalData/StatsView.vue'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
              headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
              viewEntityType: 'project',
            },
            props: true,
          },
          {
            path: 'incidents',
            name: 'project-subscriber-medical-data-incidents',
            component: () => import('@/modules/projectUsers/subscriber/views/medicalData/MedicalIncidentView.vue'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
              headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
              viewEntityType: 'project',
            },
            beforeEnter: async (to, from, next) => {
              const { projectId, subscriberId } = to.params
              await store.dispatch('subscriber/bindMedicalIncidents', { projectId, subscriberId })
              next()
            },
            props: true,
            redirect: { name: 'project-subscriber-medical-data-incidents-list' },
            children: [
              {
                path: 'list',
                name: 'project-subscriber-medical-data-incidents-list',
                component: () => import('@/modules/projectUsers/subscriber/views/medicalData/MedicalIncident/MedicalIncidentListView.vue'),
                meta: {
                  title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
                  headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
                  viewEntityType: 'project',
                },
                props: true,
              },
              {
                path: 'create',
                name: 'project-subscriber-medical-data-incidents-create',
                component: () => import('@/modules/projectUsers/subscriber/views/medicalData/MedicalIncident/EditMedicalIncidentView.vue'),
                meta: {
                  title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
                  headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
                  viewEntityType: 'project',
                },
                props: true,
              },
              {
                path: 'edit/:medicalIncidentId',
                name: 'project-subscriber-medical-data-incidents-edit',
                component: () => import('@/modules/projectUsers/subscriber/views/medicalData/MedicalIncident/EditMedicalIncidentView.vue'),
                meta: {
                  title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
                  headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
                  viewEntityType: 'project',
                },
                props: true,
              },
            ],
          },
          {
            path: 'evaulations',
            name: 'project-subscriber-medical-data-evaluations',
            component: () => import('@/modules/projectUsers/subscriber/views/medicalData/MedicalEvaluationsView.vue'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
              headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
              viewEntityType: 'project',
            },
            props: true,
            redirect: { name: 'project-subscriber-medical-data-evaluations-list' },
            children: [
              {
                path: 'list',
                name: 'project-subscriber-medical-data-evaluations-list',
                component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/MedicalEvaluationListView.vue'),
                meta: {
                  title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
                  headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
                  viewEntityType: 'project',
                },
              },
              {
                path: 'create',
                name: 'project-subscriber-medical-data-evaluations-create',
                component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/MainView.vue'),
                meta: {
                  title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
                  viewEntityType: 'project',
                },
                props: true,
                redirect: { name: 'project-subscriber-medical-data-evaluations-create-step-1' },
                children: childrenRoutesEvaluationsEdit,
              },
              {
                path: 'edit/:medicalEvaluationId',
                name: 'project-subscriber-medical-data-evaluations-edit',
                component: () => import('@/modules/projectUsers/subscriber/views/medicalData/medicalEvaluations/editMedicalEvaluation/MainView.vue'),
                meta: {
                  title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
                  viewEntityType: 'project',
                },
                props: true,
                redirect: { name: 'project-subscriber-medical-data-evaluations-edit-step-1' },
                children: childrenRoutesEvaluationsEdit.map(routeEvalutionEdit => ({ ...routeEvalutionEdit, name: routeEvalutionEdit.name.replace('create', 'edit') })),
              },
            ],
          },
        ],
      },
      {
        path: 'academic-data',
        name: 'project-subscriber-academic-data',
        component: () => import('@/modules/projectUsers/subscriber/views/AcademicDataView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
          viewEntityType: 'project',
        },
      },
      {
        path: 'attendance',
        name: 'project-subscriber-attendance',
        component: () => import('@/modules/projectUsers/subscriber/views/AttendanceView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.users.playerDetail-${store.getters['project/data']?.type}`),
          viewEntityType: 'project',
        },
        props: true,
      },
    ],
  },
]
