import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'subscribers/:subscriberId/project/:projectId',
    name: 'organization-subscriber-project',
    component: () => import('@/modules/projectUsers/subscriber/views/SubscriberMainView.vue'),
    meta: {
      title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.playerDetail')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.playerDetail'),
    },
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId } = to.params
      if (store.state.project.dbData?.id !== projectId) await store.dispatch('project/bind', { organizationId, projectId })
      next()
    },
    redirect: { name: 'organization-subscriber-project-general-information' },
    props: true,
    children: [
      {
        path: 'general-information',
        name: 'organization-subscriber-project-general-information',
        component: () => import('@/modules/projectUsers/subscriber/views/GeneralInformationView.vue'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.playerDetail')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.playerDetail'),
          viewEntityType: 'organization',
        },
        props: true,
      },
      {
        path: 'attendance',
        name: 'organization-subscriber-project-attendance',
        component: () => import('@/modules/projectUsers/subscriber/views/AttendanceView.vue'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.playerDetail')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.playerDetail'),
          viewEntityType: 'organization',
        },
        props: true,
      },
      {
        path: 'payments',
        name: 'organization-subscriber-project-payments',
        component: () => import('@/modules/projectUsers/subscriber/views/PaymentsView.vue'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.playerDetail')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.playerDetail'),
          viewEntityType: 'organization',
        },
        props: true,
      },
    ],
  },
]
