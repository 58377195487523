import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: '/superadmin',
    name: 'superadmin',
    component: () => import('@/modules/superadmin/Superadmin'),
    redirect: { name: 'superadmin-organizations' },
    beforeEnter: async (to, from, next) => {
      // Binds
      if (!store.state.superadmin.dbOrganizations.length) await store.dispatch('superadmin/bindOrganizations')
      if (!store.state.superadmin.dbExternalPartners.length) await store.dispatch('superadmin/bindExternalVendors')
      if (!store.state.superadmin.dbRolePresets.length) await store.dispatch('superadmin/bindRolePresets')

      // Security
      if (!store.getters['superadmin/isUserSuperadmin']) next({ name: '404' })
      else next()
    },
    children: [
      {
        path: 'organizations',
        name: 'superadmin-organizations',
        component: () => import('@/modules/superadmin/OrganizationList'),
        meta: {
          title: () => `${i18n.t('superadmin.sections.organizations')} - ${i18n.t('superadmin.name')} - OWQLO`,
          headerTitle: () => i18n.t('superadmin.sections.organizations'),
        },
      },

      {
        path: 'activity',
        name: 'superadmin-activity',
        component: () => import('@/modules/superadmin/Activity'),
        meta: {
          title: () => `${i18n.t('superadmin.sections.activity')} - ${i18n.t('superadmin.name')} - OWQLO`,
          headerTitle: () => i18n.t('superadmin.sections.activity'),
        },
      },
      {
        path: 'people',
        name: 'superadmin-people',
        component: () => import('@/modules/superadmin/Members'),
        meta: {
          title: () => `${i18n.t('superadmin.sections.people')} - ${i18n.t('superadmin.name')} - OWQLO`,
          headerTitle: () => i18n.t('superadmin.sections.people'),
        },
      },
      {
        path: 'partners',
        name: 'superadmin-partners',
        component: () => import('@/modules/superadmin/views/PartnersMainView.vue'),
        meta: {
          title: () => `${i18n.t('superadmin.sections.partners')} - ${i18n.t('superadmin.name')} - OWQLO`,
          headerTitle: () => i18n.t('superadmin.sections.partners'),
        },
      },
    ],
  },
]
