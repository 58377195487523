import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import getEmailLoginLink from '@/utils/getEmailLoginLink'

export default {
  namespaced: true,
  state: () => ({
    dbVendor: null,
    dbVendors: [],
    dbProducts: [],
    dbManagers: [],
    dbShippingZones: [],
    dbShippingRates: [],
  }),
  getters: {
    vendor: state => state.dbVendor,
    vendors: state => [...state.dbVendors],
    products: state => state.dbProducts,
    managers: state => state.dbManagers,
    isUserVendorManager: (state, getters, rootState, rootGetters) => rootGetters['user/managerVendorIds'].includes(state.dbVendor?.id) || rootGetters['superadmin/isUserSuperadmin'],
    shippingZones: state => [...state.dbShippingZones.sort((a, b) => a?.createdAt?.toDate() - b?.createdAt?.toDate())],
    shippingRates: state => [...state.dbShippingRates.sort((a, b) => a?.createdAt?.toDate() - b?.createdAt?.toDate())],
  },
  actions: {
    // Binds
    bind: firestoreAction(({ bindFirestoreRef }, { vendorId }) => bindFirestoreRef('dbVendor', db.collection('vendors').doc(vendorId))),
    bindVendors: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef(
      'dbVendors',
      db.collection('vendors').where('organizationId', '==', organizationId),
    )),
    bindProducts: firestoreAction(({ bindFirestoreRef }, { vendorId }) => bindFirestoreRef(
      'dbProducts',
      db.collection('products').where('vendorId', '==', vendorId),
    )),
    bindManagers: firestoreAction(({ bindFirestoreRef }, { vendorId }) => bindFirestoreRef(
      'dbManagers',
      db.collection('users').where(`roles.byVendor.${vendorId}.roles`, 'array-contains', 'manager'),
    )),
    bindShippingZones: firestoreAction(({ bindFirestoreRef }, { vendorId }) => bindFirestoreRef(
      'dbShippingZones',
      db.collection('shippingZones').where('vendorId', '==', vendorId),
    )),
    bindShippingRates: firestoreAction(({ bindFirestoreRef }, { vendorId }) => bindFirestoreRef(
      'dbShippingRates',
      db.collection('shippingRates').where('vendorId', '==', vendorId),
    )),
    unbindProducts: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbProducts')),
    unbindVendors: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbVendors')),
    unbindShippingZones: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbShippingRates')),
    unbindShippingRates: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbShippingZones')),
    createStripeAccount: (context, { organizationId, currency }) => callBackend('stripe/accounts/create', { organizationId, currency }),

    // Create
    async create(context, { organizationId, data }) {
      const vendorRef = db.collection('vendors').doc()
      const vendor = { id: vendorRef.id, organizationId, ...data, createdAt: FieldValue.serverTimestamp(), updatedAt: FieldValue.serverTimestamp() }
      await vendorRef.set(vendor)
      return vendorRef.id
    },
    createShippingZone: async (context, { shippingZoneId, data }) => {
      const shippingZoneRef = db.collection('shippingZones').doc(shippingZoneId)
      const createdAt = FieldValue.serverTimestamp()
      const shippingZone = { ...data, id: shippingZoneId, createdAt, updatedAt: createdAt }
      await shippingZoneRef.set(shippingZone)
    },
    createShippingRate: async (context, { shippingRateId, data }) => {
      const shippingRateRef = db.collection('shippingRates').doc(shippingRateId)
      const createdAt = FieldValue.serverTimestamp()
      const shippingRate = { ...data, id: shippingRateId, createdAt, updatedAt: createdAt }
      await shippingRateRef.set(shippingRate)
    },
    async addManager({ state, getters, dispatch, rootState }, { vendorId, email, role }) {
      const entity = { type: 'vendor', role, vendorId }
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      const lang = rootState.locale
      if (user) {
        const isAlreadyManager = getters.managers.map(manager => manager.id).includes(user.id)
        if (isAlreadyManager) {
          const manager = getters.managers.find(s => s.id === user.id)
          if (manager.role === role) return false
          await dispatch('removeManager', { vendorId, user: manager, role })
        }
        await subscribeToEntity({ userId: user.id, entity, lang, isStaff: true })
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({ entitiesToSubscribe: [entity], id: email, secretKey, createdAt: FieldValue.serverTimestamp() })
        callBackend('emails/subscribe', {
          to: email,
          lang,
          params: {
            role,
            platform: 'web',
            vendorId: state.dbVendor.id,
            webLink: await getEmailLoginLink({ isNewUser: true, email, secretKey }),
            isNewUser: true,
          },
        })
      }
      return true
    },

    // Read
    async readVendorsByOrganizationId(context, { organizationId }) {
      return (await db.collection('vendors').where('organizationId', '==', organizationId).get()).docs.map(snap => snap.data())
    },
    async readVendor(context, { vendorId }) {
      return (await db.collection('vendors').doc(vendorId).get()).data()
    },
    async readShippingZonesByVendor(context, { vendorId }) {
      const collectionSnap = await db.collection('shippingZones').where('vendorId', '==', vendorId).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    async readShippingRate(context, { id }) {
      return (await db.collection('shippingRates').doc(id).get()).data()
    },
    async readShippingRatesByVendorId(context, { vendorId }) {
      return (await db.collection('shippingRates').where('vendorId', '==', vendorId).orderBy('createdAt').get()).docs.map(snap => snap.data())
    },
    async readShippingZone(context, { shippingZoneId }) {
      return (await db.collection('shippingZones').doc(shippingZoneId).get()).data()
    },

    // Update
    async update(context, { vendorId, data }) {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection('vendors').doc(vendorId).update(dataToUpdate)
      return vendorId
    },
    updateShippingZone: async (context, { shippingZoneId, data }) => {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection('shippingZones').doc(shippingZoneId).update(dataToUpdate)
    },
    updateShippingRate: async (context, { shippingRateId, data }) => {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection('shippingRates').doc(shippingRateId).update(dataToUpdate)
    },

    // Delete
    async delete(context, { vendorId }) {
      await db.collection('vendors').doc(vendorId).delete()
      await context.dispatch('deleteShippingRatesAndShippingZonesByVendor', { vendorId })
    },
    async deleteShippingZones(context) {
      const shippingZones = [...context.getters.shippingZones]
      await Promise.all(shippingZones.map(async sZ => { await db.collection('shippingZones').doc(sZ.id).delete() }))
      await context.dispatch('deleteShippingRates', { type: 'sendToAddress' })
    },
    async deleteShippingRates(context, { type }) {
      const shippingRates = [...context.getters.shippingRates.filter(sR => sR.type === type)]
      await context.dispatch('deleteShippingRatesArray', { shippingRates })
    },
    async deleteShippingRatesArray(context, { shippingRates }) {
      await Promise.all(shippingRates.map(async shippingRate => {
        await db.collection('shippingRates').doc(shippingRate.id).delete()
        await context.dispatch('products/deleteShippingRateId', { shippingRateId: shippingRate.id }, { root: true })
      }))
    },
    async deleteShippingRatesAndShippingZonesByVendor(context, { vendorId }) {
      const shippingRatesByVendor = [...await context.dispatch('readShippingRatesByVendorId', { vendorId })]
      await context.dispatch('deleteShippingRatesArray', { shippingRates: shippingRatesByVendor })

      const shippingZonesByVendor = [...await context.dispatch('readShippingZonesByVendor', { vendorId })]
      await Promise.all(shippingZonesByVendor.map(async sZ => { await db.collection('shippingZones').doc(sZ.id).delete() }))
    },
    async removeManager(context, { vendorId, user, role }) {
      const entity = { type: 'vendor', role, vendorId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      return true
    },
    deleteShippingZone: async (context, { shippingZoneId }) => {
      await db.collection('shippingZones').doc(shippingZoneId).delete()
      await Promise.all(context.getters.shippingRates.filter(sR => sR.shippingZoneId === shippingZoneId).map(async shippingRate => {
        await db.collection('shippingRates').doc(shippingRate.id).delete()
        await context.dispatch('products/deleteShippingRateId', { shippingRateId: shippingRate.id }, { root: true })
      }))
    },
    deleteShippingRate: async (context, { shippingRateId }) => {
      await db.collection('shippingRates').doc(shippingRateId).delete()
      await context.dispatch('products/deleteShippingRateId', { shippingRateId }, { root: true })
    },
  },
}
