import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'activity/:activityId',
    name: 'activity',
    component: () => import('@/modules/activity/views/lists/ActivityList.vue'),
    redirect: { name: 'activity-form' },
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, activityId } = to.params
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) await store.dispatch('organization/bind', organizationId)
      if (store.state.project.dbData?.id !== projectId) await store.dispatch('project/bind', { organizationId, projectId })
      if (store.state.activity.dbData?.id !== activityId) {
        const entity = await store.dispatch('activity/bind', { organizationId, projectId, activityId })
        if (!entity) next({ name: '404' })
        await store.dispatch('activity/bindComments', { organizationId, projectId, activityId })
      }
      const streamingId = store.state.activity.dbData?.streamingChannel
      if (store.state.activity.dbData?.contentType === 'streaming' && store.state.streaming.dbData?.id !== streamingId) {
        await store.dispatch('streaming/bind', { organizationId, streamingId })
      }
      store.commit('loader/hide')
      next()
    },
    props: true,
    children: [
      {
        path: 'content',
        name: 'activity-form',
        component: () => import('@/modules/activity/views/forms/ActivityForm.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.activities')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
      {
        path: 'attendance',
        name: 'activity-attendance',
        component: () => import('@/modules/activity/views/lists/ActivityAttendanceList.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.activities')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
      {
        path: 'comments',
        name: 'activity-comments',
        component: () => import('@/modules/activity/views/lists/ActivityCommentList.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('common.comments')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
      {
        path: 'streaming-stats',
        name: 'activity-streaming-stats',
        component: () => import('@/modules/activity/views/lists/ActivityStreamingStatsList.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('streamingStats.title')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
      {
        path: 'tickets',
        name: 'activity-tickets',
        component: () => import('@/modules/activity/views/lists/ActivityTicketsList.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('organization.sections.tickets')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
    ],
  },
  {
    path: 'create-activity/:type/:date/:hour',
    name: 'create-activity-calendar',
    component: () => import('@/modules/activity/views/forms/ActivityForm.vue'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.createActivity')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.createActivity'),
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('activity/unbind')
      next()
    },
    props: true,
  },
  {
    path: 'create-activity/:type/:date',
    name: 'create-activity-table',
    component: () => import('@/modules/activity/views/forms/ActivityForm.vue'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.createActivity')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.createActivity'),
    },
    props: true,
  },
]
