import projectTypeEnum from '@/enums/projectTypeEnum'
import store from '@/store'

export default () => {
  const project = store.getters['project/data']
  const hasPermission = params => store.getters['project/hasPermission'](params)
  return [
    {
      id: 'settings',
      name: 'project.sections.settings',
      icon: 'settings',
      show: true,
    },
    {
      id: 'globalSettings',
      name: 'project.sections.generalInformation',
      route: { name: 'project-edit' },
      parentId: 'settings',
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.COMPETITION, projectTypeEnum.SHORT, projectTypeEnum.LONG].includes(project.type)
        && hasPermission('globalSettings-show_section'),
    },
    {
      id: 'globalSettingsGuild',
      name: 'project.sections.generalInformation',
      route: { name: 'guild-edit' },
      parentId: 'settings',
      show: [projectTypeEnum.GUILD, projectTypeEnum.GUILDCLUB].includes(project.type)
        && hasPermission('globalSettingsGuild-show_section'),
    },
    {
      id: 'competitionStructure',
      name: 'competition.sections.structure',
      icon: 'camera',
      route: { name: 'competition-structure' },
      parentId: 'settings',
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'categories',
      name: 'project.sections.categories',
      route: { name: 'project-categories-and-teams' },
      parentId: 'settings',
      show: [projectTypeEnum.ACADEMY].includes(project.type)
        && hasPermission('categories-show_section')
        && !window.isFutbolistasOn,
    },
    {
      id: 'locations',
      name: `competition.sections.locations-${project.type}`,
      route: { name: 'locations' },
      parentId: 'settings',
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.COMPETITION].includes(project.type)
        && project.features.hasLocations
        && hasPermission('locations-show_section'),
    },
    {
      id: 'sponsorization',
      name: 'project.sections.sponsorization',
      icon: 'camera',
      route: { name: 'project-sponsorization' },
      parentId: 'settings',
      show: [projectTypeEnum.COMPETITION].includes(project.type)
        && hasPermission('sponsorization-show_section'),
    },
    {
      id: 'termsAndConditions',
      name: 'common.t&c',
      icon: 'settings',
      route: { name: 'project-terms-and-conditions' },
      parentId: 'settings',
      show: hasPermission('termsAndConditions-show_section'),
    },
    {
      id: 'keywords',
      name: 'project.sections.keywords',
      icon: 'settings',
      route: { name: 'project-keywords' },
      parentId: 'settings',
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.COMPETITION].includes(project.type)
        && hasPermission('globalSettingsGuild-show_section')
        && window.isDev,
    },
    {
      id: 'accomodations',
      name: 'project.sections.accomodations',
      icon: 'hotel',
      route: { name: 'project-accomodations' },
      parentId: 'settings',
      show: [projectTypeEnum.COMPETITION].includes(project.type)
        && project?.features?.accomodationForm
        && hasPermission('accomodations-show_section'),
    },
    {
      id: 'guildBenefitRequestForm',
      name: 'guild.sections.benefitRequest',
      icon: 'paid',
      route: { name: 'guild-benefit-requests-form' },
      /* show: [projectTypeEnum.GUILD].includes(project.type)
        && hasPermission('guildBenefitRequestForm-show_section'), */
      show: false,
    },
    {
      id: 'enrollmentSection',
      name: 'project.sections.enrollmentsTitle',
      icon: 'playlist_add_check',
      show: true,
    },
    {
      id: 'form',
      name: 'project.sections.form',
      route: { name: 'inscription-form' },
      parentId: 'enrollmentSection',
      show: project.features?.form
        && [projectTypeEnum.ACADEMY, projectTypeEnum.COMPETITION, projectTypeEnum.SHORT, projectTypeEnum.LONG].includes(project.type)
        && hasPermission('form-show_section'),
    },
    {
      id: 'clubsForm',
      name: 'project.sections.clubsForm',
      route: { name: 'competition-club-enrollment-form-edit' },
      parentId: 'enrollmentSection',
      show: [projectTypeEnum.COMPETITION].includes(project.type)
        && hasPermission('clubsForm-show_section'),
    },
    {
      id: 'payments',
      name: 'project.sections.payments',
      icon: 'credit_card',
      show: true,
    },
    {
      id: 'paymentsSettings',
      name: 'project.sections.settings',
      route: { name: 'project-payments-settings' },
      parentId: 'payments',
      show: hasPermission('paymentsSettings-show_section'),
    },
    {
      id: 'paymentTypes',
      name: 'project.sections.paymentTypes',
      route: { name: 'project-payment-types' },
      parentId: 'payments',
      show: project.features?.price && hasPermission('paymentsSettings-show_section'),
    },
    {
      id: 'paymentsHistory',
      name: 'common.history',
      route: { name: 'project-payments-history' },
      parentId: 'payments',
      show: hasPermission('paymentsHistory-show_section'),
    },
    {
      id: 'coupons',
      name: 'project.sections.coupons',
      route: { name: 'project-coupons' },
      parentId: 'payments',
      show: hasPermission('paymentsSettings-show_section'),
    },
    {
      id: 'emailAdmission',
      name: 'project.sections.emailAdmission',
      icon: 'send',
      route: { name: 'project-emailAdmission' },
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.COMPETITION, projectTypeEnum.SHORT, projectTypeEnum.LONG].includes(project.type)
        && hasPermission('emailAdmission-show_section'),
    },
    {
      id: 'users',
      name: 'project.sections.users',
      icon: 'person_outline',
      show: true,
      notifications: true,
    },
    {
      id: 'players',
      name: `project.sections.players-${project.type}`,
      route: { name: 'project-subscribers' },
      parentId: 'users',
      show: true,
    },
    {
      id: 'staff',
      name: 'project.sections.staff',
      route: { name: 'project-staff' },
      parentId: 'users',
      show: true,
    },
    {
      id: 'enrollments',
      name: 'project.sections.enrollments',
      route: { name: 'project-enrollments' },
      parentId: 'users',
      notifications: true,
      show: hasPermission('enrollments-show_section'),
    },
    {
      id: 'autoGenerateEnrollments',
      name: 'project.sections.autoGenerateEnrollments',
      route: { name: 'project-auto-generate-enrollments' },
      parentId: 'users',
      show: store.getters['superadmin/isUserSuperadmin'],
    },
    {
      id: 'multimediaGallery',
      name: 'project.sections.multimediaGallery',
      icon: 'play_circle_outline',
      route: { name: 'project-multimedia-gallery' },
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.COMPETITION].includes(project.type)
        && hasPermission('multimediaGallery-show_section'),
    },
    {
      id: 'activities',
      name: 'project.sections.activities',
      icon: 'sports',
      route: { name: 'project-calendar', params: { date: 'all' } },
      show: [projectTypeEnum.SHORT, projectTypeEnum.LONG].includes(project.type),
    },
    {
      id: 'schedule',
      name: 'project.sections.schedule',
      icon: 'calendar_today',
      route: { name: 'calendar-schedule' },
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.GUILD, projectTypeEnum.GUILDCLUB].includes(project.type),
    },
    {
      id: 'news',
      name: 'project.sections.posts',
      icon: 'wysiwyg',
      route: { name: 'project-posts' },
      show: true,
    },
    {
      id: 'eventLog',
      name: 'project.sections.eventLog',
      icon: 'schedule',
      route: { name: 'project-eventLog' },
      show: [projectTypeEnum.ACADEMY, projectTypeEnum.SHORT, projectTypeEnum.LONG].includes(project.type),

    },
    {
      id: 'clubEnrollments',
      name: 'competition.sections.clubEnrollments',
      icon: 'format_list_bulleted',
      route: { name: 'competition-club-enrollments' },
      show: [projectTypeEnum.COMPETITION].includes(project.type)
        && hasPermission('enrollments-show_section'),
    },
    {
      id: 'clubs',
      name: 'competition.sections.clubs',
      icon: 'shield',
      route: { name: 'competition-clubs' },
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'groups',
      name: 'project.sections.groups',
      icon: 'person-group-outline',
      route: { name: 'competition-groups' },
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'brackets',
      name: 'project.sections.brackets',
      icon: 'shuffle',
      route: { name: 'competition-brackets' },
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'matchesFilter',
      name: 'competition.sections.cohortsMatches',
      icon: 'sports_basketball',
      route: { name: 'competition-matches-filter' },
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'bans',
      name: 'competition.sections.bans',
      icon: 'shield',
      show: true,
    },
    {
      id: 'createBans',
      name: 'competition.sections.createBans',
      route: { name: 'competition-create-bans' },
      parentId: 'bans',
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'manageBans',
      name: 'competition.sections.manageBans',
      route: { name: 'competition-manage-bans' },
      parentId: 'bans',
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'rankingFilter',
      name: 'competition.sections.ranking',
      icon: 'emoji_events',
      route: { name: 'competition-ranking-filter' },
      show: [projectTypeEnum.COMPETITION].includes(project.type),
    },
    {
      id: 'info',
      name: 'common.info',
      icon: 'book',
      route: { name: 'competition-info' },
      show: [projectTypeEnum.COMPETITION].includes(project.type) && store.getters['project/isCompetition'] && store.getters['superadmin/isUserSuperadmin'],
    },
    {
      id: 'pushNotifications',
      name: 'organization.sections.pushNotifications',
      icon: 'add_alert',
      route: { name: 'project-pushNotifications' },
      show: true,
    },
    {
      id: 'products',
      name: 'common.products',
      icon: 'shopping_cart',
      show: true,
    },
    {
      id: 'projectProducts',
      name: 'project.sections.settings',
      route: { name: 'project-products' },
      parentId: 'products',
      show: true,
    },
    {
      id: 'projectOrders',
      name: 'project.sections.orders',
      route: { name: 'project-list-orders' },
      parentId: 'products',
      show: true,
    },
    {
      id: 'publish',
      type: 'button',
      text: 'project.actions.publish',
      show: !project.published && hasPermission('publishProject'),
    },
    {
      id: 'openCompetition',
      type: 'button',
      text: 'project.actions.openCompetition',
      show: [projectTypeEnum.COMPETITION].includes(project.type) && project.published && !project.isOpen,
    },
    {
      id: 'divider-3',
      type: 'divider',
      isStyle: true,
      show: true,
    },
  ]
}
