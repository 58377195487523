import { db, uploadFileAndGetUrl, callBackend, FieldValue } from '@/services/firebase'

export default {
  namespaced: true,
  actions: {
    // Create
    async create(context, { organizationId, projectId, data }) {
      const assetRef = db.collection(`properties/${organizationId}/projects/${projectId}/assets`).doc()
      const storagePath = `properties/${organizationId}/projects/${projectId}/assets/${assetRef.id}`
      const url = await uploadFileAndGetUrl(storagePath, data.url)
      const dataToSave = { id: assetRef.id, ...data, createdAt: FieldValue.serverTimestamp(), updatedAt: FieldValue.serverTimestamp(), url }
      await assetRef.set(dataToSave)
    },

    // Update
    async update(context, { organizationId, projectId, assetId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/assets/${assetId}`
      const url = await uploadFileAndGetUrl(storagePath, data.url)
      const dataToUpdate = { ...data, url, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects/${projectId}/assets`).doc(assetId).update(dataToUpdate)
    },

    // Delete
    async delete(context, { organizationId, projectId, assetId }) {
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/assets/${assetId}` })
    },
  },
}
