<template>
  <div class="d-flex align-center">
    <v-icon v-if="text && copy" color="primary" size="18" class="mr-1" @click.stop="copyToClipboard(text)" v-text="'content_copy'" />
    <v-tooltip top color="white">
      <template #activator="{ on, attrs }">
        <span :class="truncate ? ['d-inline text-truncate has-max-width'] : ['d-flex']" v-bind="attrs" v-on=" truncate && copy ? on : null" v-text="text" />
      </template>
      <div class="tooltip-text pa-2" v-text="text" />
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'CopyText',
  props: {
    text: {
      type: String,
      default: '',
    },
    truncate: {
      type: Boolean,
      default: true,
    },
    copy: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-avatar img {
  width: 100%;
  object-fit: cover;
}

.has-max-width {
  max-width: 7rem;
}
</style>
