import { firestoreAction } from 'vuexfire'
import { db, FieldValue, uploadFileAndGetUrl, uploadFileAndGetObj, callBackend } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getModifiedFields from '../../utils/getModifiedFields'
import i18n from '@/plugins/i18n'
import projectRouteNames from '@/enums/projectRouteNames'

const getDbFields = async ({ organizationId, projectId, projectIds, clusterIds, postRef, post, data, publish, rootGetters, dotNotation = true }) => {
  const banner = data.banner ? await uploadFileAndGetUrl(postRef.path, data.banner) : null
  const attachments = await Promise.all(data.attachments.map(att => uploadFileAndGetObj(postRef.path, att)))
  const modifiedFields = getModifiedFields(post, { ...data, banner, attachments, published: publish, active: true, id: postRef.id }, dotNotation)
  const newUserIds = Object.keys(modifiedFields).some(field => field.startsWith('userSources'))
    ? await callBackend('activities/get-user-ids', { organizationId, projectId, userSources: data.userSources })
    : null
  return {
    organizationId,
    ...modifiedFields,
    updatedAt: FieldValue.serverTimestamp(),
    ...(projectIds && { projectIds }),
    ...(clusterIds && { clusterIds }),
    ...(!Object.keys(post).length && {
      createdAt: FieldValue.serverTimestamp(),
      createdBy: rootGetters['user/data'].id,
      isLaliga: false,
      likes: 0,
    }),
    ...(!post.published && publish && { publishedAt: FieldValue.serverTimestamp() }),
    ...(!post.userIds && !newUserIds && { userIds: ['all'] }),
    ...(newUserIds && { userIds: newUserIds }),
  }
}

const doFirstTimePublishActions = async ({ organizationId, post, rootState, rootGetters, dispatch }) => {
  // Send notifications
  const createNotification = notificationData => {
    if (post.userIds.includes('all')) callBackend('notifications/push/create', notificationData)
    else callBackend('notifications/push/create-multiple', { ...notificationData, userIds: post.userIds })
  }
  let notificationData = {
    scope: post.scope,
    context: { organizationId, postId: post.id },
    data: {
      translation: { id: 'postPublished', params: { postTitle: post.name } },
      linkType: 'postDetail',
    },
    language: rootState.locale,
  }
  if (notificationData.scope === 'project') {
    if (rootGetters['project/doNotSendNotificationOnActivityPublish']) return
    post.projectIds.map(async pId => {
      const project = await dispatch('project/read', { organizationId, projectId: pId }, { root: true })
      const context = { ...notificationData.context, projectId: pId }
      const language = project?.language
      notificationData = { ...notificationData, context, language }
      createNotification(notificationData)
    })
  } else if (notificationData.scope === 'cluster') {
    post.clusterIds.map(async cId => {
      const context = { ...notificationData.context, clusterId: cId }
      notificationData = { ...notificationData, context }
      createNotification(notificationData)
    })
  } else if (notificationData.scope === 'organization') {
    createNotification(notificationData)
  }
  // Add user alerts
  callBackend('users/add-alerts', { userIds: post.userIds, type: 'academy' })
}

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbPosts: [],
  }),
  getters: {
    data: state => state.dbData,
    posts: state => state.dbPosts,
  },
  actions: {
    bindPosts: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef('dbPosts', db.collection(`properties/${organizationId}/posts`))),
    unbindPosts: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPosts')),
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, postId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/posts`).doc(postId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    async create({ rootState, rootGetters, dispatch }, { organizationId, projectId, projectIds, clusterIds, data, publish = true }) {
      const postRef = db.collection(`properties/${organizationId}/posts`).doc()
      const post = await getDbFields({ organizationId, projectId, projectIds, clusterIds, postRef, post: {}, data, publish, rootGetters, dotNotation: false })
      await postRef.set(post)
      if (publish) await doFirstTimePublishActions({ organizationId, post, rootState, rootGetters, dispatch })
      // logEvent({ action: 'create', entityType: projectRouteNames.POST, entity: post })
      return postRef.id
    },
    async createClone({ getters }, { organizationId, data }) {
      const originalPost = getters.posts.find(post => post.id === data.id)
      const newPostRef = db.collection(`properties/${organizationId}/posts`).doc()
      const newPost = {
        ...originalPost,
        id: newPostRef.id,
        name: `${originalPost.name} - ${i18n.t('common.copy')}`,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newPostRef.set(newPost)
      // logEvent({ action: 'create', entityType: projectRouteNames.POST, entity: newPost })
      return newPostRef.id
    },
    async update({ rootGetters, dispatch }, { organizationId, projectId, projectIds, clusterIds, post, data, publish = true }) {
      const postRef = db.collection(`properties/${organizationId}/posts`).doc(post.id)
      const dataToUpdate = await getDbFields({ organizationId, projectId, projectIds, clusterIds, postRef, post, data, publish, rootGetters })
      await postRef.update(dataToUpdate)
      // if (publish) {
      //   if (post.name !== data.name) {
      //     logEvent({
      //       action: 'rename',
      //       entityType: projectRouteNames.POST,
      //       entity: post,
      //       newName: data.name,
      //     })
      //   }
      // }
      // logEvent({ action: 'update', entityType: projectRouteNames.POST, entity: post })
      if (publish) {
        if (!post.published) await doFirstTimePublishActions({ organizationId, projectId, post: { ...post, ...dataToUpdate }, rootGetters, dispatch })
      }
      return true
    },
    async publish(context, { organizationId, data }) {
      await db.collection(`properties/${organizationId}/posts`).doc(data.id).update({ published: true, publishedAt: FieldValue.serverTimestamp() })
      logEvent({ action: 'publish', entityType: projectRouteNames.POST, entity: data })
      return true
    },
    async archive(context, { organizationId, data }) {
      await db.collection(`properties/${organizationId}/posts`).doc(data.id).update({ active: false })
      logEvent({ action: 'archive', entityType: projectRouteNames.POST, entity: data })
      return true
    },
    async unarchive(context, { organizationId, data }) {
      await db.collection(`properties/${organizationId}/posts`).doc(data.id).update({ active: true, published: false })
      logEvent({ action: 'unarchive', entityType: projectRouteNames.POST, entity: data })
      return true
    },
    async delete(context, { organizationId, data }) {
      const path = `properties/${organizationId}/posts/${data.id}`
      await callBackend('recursive-delete', { path })
      logEvent({ action: 'delete', entityType: projectRouteNames.POST, entity: data })
      return true
    },
  },
}
