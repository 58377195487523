import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: '/organization/:organizationId/project/:projectId/inscription-form',
    name: 'inscription-form',
    component: () => import('@/modules/inscriptionForm/views/MainView.vue'),
    props: true,
    meta: {
      title: () => `${i18n.t('project.sections.form')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.form'),
    },
    redirect: { name: 'inscription-form-step-1' },
    children: [
      {
        path: 'step-1',
        name: 'inscription-form-step-1',
        component: () => import('@/modules/inscriptionForm/views/steps/1WebStyleView.vue'),
        meta: {
          stepId: 1,
          title: () => `${i18n.t('project.sections.form')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.form'),
        },
        props: true,
      },
      {
        path: 'step-2',
        name: 'inscription-form-step-2',
        component: () => import('@/modules/inscriptionForm/views/steps/2ParentDataView.vue'),
        meta: {
          stepId: 2,
          title: () => `${i18n.t('project.sections.form')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.form'),
        },
        props: true,
      },
      {
        path: 'step-3',
        name: 'inscription-form-step-3',
        component: () => import('@/modules/inscriptionForm/views/steps/3PlayerDataView.vue'),
        meta: {
          stepId: 3,
          title: () => `${i18n.t('project.sections.form')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.form'),
        },
        props: true,
      },
      {
        path: 'step-4',
        name: 'inscription-form-step-4',
        component: () => import('@/modules/inscriptionForm/views/steps/4WaiverView.vue'),
        meta: {
          stepId: 4,
          title: () => `${i18n.t('project.sections.form')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.form'),
        },
        props: true,
      },
      {
        path: 'step-5',
        name: 'inscription-form-step-5',
        component: () => import('@/modules/inscriptionForm/views/steps/5SummaryView.vue'),
        meta: {
          stepId: 5,
          title: () => `${i18n.t('project.sections.form')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.form'),
        },
        props: true,
      },
    ],
  },
]
