import { firestoreAction } from 'vuexfire'
import { db } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbOrganizations: [],
    dbExternalPartners: [],
    dbRolePresets: [],
  }),
  getters: {
    isUserSuperadmin: (state, getters, rootState) => !!rootState.user.dbData?.roles?.roles?.includes('superadmin'),
    organizations: (state, getters, rootState) => state.dbOrganizations.map(organization => ({
      ...organization,
      isFavorite: rootState.user.dbData?.favorites?.organizations?.includes(organization.id),
    })),
    partners: (state, getters, rootState) => state.dbExternalPartners.map(partner => ({
      ...partner,
      isFavorite: rootState.user.dbData?.favorites?.partners?.includes(partner.id) || false,
    })),
    rolePresets: state => state.dbRolePresets,
  },
  actions: {
    bindOrganizations: firestoreAction(({ bindFirestoreRef }) => bindFirestoreRef('dbOrganizations', db.collection('properties'))),
    bindExternalVendors: firestoreAction(({ bindFirestoreRef }) => bindFirestoreRef('dbExternalPartners', db.collection('vendors').where('isExternal', '==', true))),
    bindRolePresets: firestoreAction(({ bindFirestoreRef }) => bindFirestoreRef('dbRolePresets', db.collection('rolePresets'))),
  },
}
