import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    ...mapActions('alert', ['openAlertBox']),
    // Use when you want to show loaders and success/error alerts
    async runAsync(func, { successAlert = true, successAlertCustom = null, errorAlertCustom = null } = {}) {
      this.showLoader()
      try {
        await func()
        if (successAlert) this.openAlertBox(['alertSuccess', successAlertCustom || 'Success'])
      } catch (err) {
        console.error(err)
        this.openAlertBox(['alertError', errorAlertCustom || err.message])
      }
      this.hideLoader()
    },
  },
}
