import { firestoreAction } from 'vuexfire'
import { db, FieldValue } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbData: [],
  }),
  getters: {
    data: state => state.dbData[0],
  },
  actions: {
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId, projectId }) => bindFirestoreRef('dbData', db.collection(`properties/${organizationId}/benefitRequests`)
      .where('clusterId', '==', clusterId).where('projectId', '==', projectId))),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    // Create
    async create(context, { organizationId, projectId, clusterId, data }) {
      const benefitRequestRef = db.collection(`properties/${organizationId}/benefitRequests`).doc()
      const dataToSave = { ...data, clusterId, projectId, id: benefitRequestRef.id, status: 'pendingApproval', createdAt: FieldValue.serverTimestamp() }
      await benefitRequestRef.set(dataToSave)
    },

    // Update
    async update(context, { organizationId, benefitRequestId, data }) {
      const dataToUpdate = { ...data, status: 'pendingApproval', updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/benefitRequests`).doc(benefitRequestId).update(dataToUpdate)
    },
  },
}
