import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'multimedia-gallery',
    name: 'project-multimedia-gallery',
    component: () => import('@/modules/asset/MultimediaGalleryList'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.multimediaGallery')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.multimediaGallery'),
    },
    props: true,
  },
  {
    path: 'create-multimedia',
    name: 'project-create-multimedia',
    component: () => import('@/modules/asset/EditMultimediaGallery'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.multimediaGallery')} - OWQLO`,
      headerTitle: () => i18n.t('asset.editAsset.createContent'),
    },
    props: true,
  },
  {
    path: 'edit-multimedia/:assetId',
    name: 'project-edit-multimedia',
    component: () => import('@/modules/asset/EditMultimediaGallery'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.multimediaGallery')} - OWQLO`,
      headerTitle: () => i18n.t('asset.editAsset.editContent'),
    },
    props: true,
  },
]
