export default {
  namespaced: true,
  state: () => ({
    show: false,
    message: null,
  }),
  mutations: {
    show(state, payload) {
      if (payload?.message) state.message = payload.message
      state.show = true
    },
    hide(state) {
      state.show = false
      state.message = null
    },
  },
  actions: {
    showLoader({ state }, { payload } = {}) {
      if (payload?.message) state.message = payload.message
      state.show = true
    },
    hideLoader({ state }) {
      state.show = false
      state.message = null
    },
  },
}
