import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: '/dynamic-link/verify_email',
    name: 'verify_email',
    meta: {
      title: () => `${i18n.t('user.verifyEmail')} - OWQLO`,
    },
    beforeEnter: async (to, from, next) => {
      try {
        const oobCode = to.query.oobCode
        await store.dispatch('user/verifyEmailCode', { oobCode })
        next({ name: 'status', params: { type: 'success', title: 'user.verifyEmailCodeSuccess', body: '' } })
      } catch (error) {
        next({ name: 'status', params: { type: 'error', title: 'common.anErrorHasOcurred', body: `verifyEmail.errorCodes.${error.code}` } })
      }
    },
  },
  {
    path: '/dynamic-link/set_password',
    name: 'set_password',
    component: () => import('@/modules/user/SetPassword'),
    meta: {
      title: () => `${i18n.t('setPassword.title')} - OWQLO`,
    },
  },
  {
    path: '/dynamic-link/set_password_error/:email/:errorText',
    name: 'setPasswordError',
    component: () => import('@/modules/user/SetPasswordError'),
    meta: {
      title: () => `${i18n.t('setPassword.title')} - OWQLO`,
    },
    props: true,
  },
  {
    path: '/dynamic-link*',
    name: 'dynamicLink',
    component: () => import('@/components/MatchEditorDynamicLink'),
  },
]
