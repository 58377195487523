import { firestoreAction } from 'vuexfire'
import { db, uploadFileAndGetUrl, uploadFileAndGetObj, FieldValue, callBackend } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbTeams: [],
    dbMembers: [],
  }),
  getters: {
    data: state => state.dbData,
    teams: (state, getters, rootState, rootGetters) => state.dbTeams.map(team => ({
      ...team,
      nameWithCohort: `${rootGetters['competition/cohorts'].find(c => c.id === team.cohortId)?.name}${team.suffix ? ` - ${team.suffix}` : ''}`,
    })),
    members: state => state.dbMembers,
    isClubCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabClubIds'].includes(state.dbData?.id),
  },
  actions: {
    // Read
    async read(context, { organizationId, projectId, clubId }) { return (await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId).get()).data() },
    readPrivateMemberData: async (context, { organizationId, projectId, memberId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/members/${memberId}/private`).doc('private')
      const document = await collectionSnap.get()
      return document.data()
    },
    readStaffers: async (context, { organizationId, projectId, clubId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/members`).where('clubId', '==', clubId).where('type', '==', 'staffer').where('position', '==', 'officer').get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, clubId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId),
    )),
    bindTeams: firestoreAction(({ bindFirestoreRef }, { clubId, projectId }) => bindFirestoreRef(
      'dbTeams',
      db.collectionGroup('teams').where('clubId', '==', clubId).where('projectId', '==', projectId),
    )),
    bindMembers: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, clubId }) => bindFirestoreRef(
      'dbMembers',
      db.collection(`properties/${organizationId}/projects/${projectId}/members`).where('clubId', '==', clubId),
    )),
    unbindTeams: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbTeams')),
    unbindPlayers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPlayers')),

    // Update
    async update(context, { organizationId, projectId, clubId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/clubs/${data.id}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      if (data.dynamicForm) {
        data.dynamicForm = await context.dispatch('dynamicForm/parseForm', { form: data.dynamicForm, storagePath }, { root: true })
      }

      const club = { ...data, logo, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId).update(club)
      // logEvent({ action: 'update', entityType: 'club', entity: club }) // TODO: LOGS CLUB
      return true
    },

    async updateAcceptedRightsTransfer(context, { organizationId, projectId, clubId, acceptedRightsTransfer }) {
      const dataToUpdate = { acceptedRightsTransfer, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId).update(dataToUpdate)
    },
    updateTeamSuffix(context, { organizationId, projectId, cohortId, teamId, suffix }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update({ suffix })
    },
    async updateByClubmanager({ state, getters }, { organizationId, projectId, updatedType }) {
      if (getters.isClubCollaborator) {
        await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(getters.data.id).update({
          [`managerUpdates.${updatedType}`]: FieldValue.serverTimestamp(),
        })
      }
    },

    async modifyIsApproved({ state }, { organizationId, projectId, cohortId, teamId, value }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update({ isApproved: value })
    },

    // Delete
    async delete(context, { organizationId, projectId, club }) {
      const { id: clubId } = club
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/clubs/${clubId}` })
      // logEvent({ action: 'delete', entityType: 'club', entity: club }) // TODO: LOGS CLUB
      return true
    },

    // Players
    async createPlayer({ dispatch, rootGetters }, { organizationId, projectId, clubId, cohortId, teamId, data }) {
      let avatarUrl
      const {
        avatar = null,
        firstName,
        lastName,
        legalCode,
        birthdate,
        number,
        email,
        parentEmail,
      } = data
      const storageURL = `properties/${organizationId}/projects/${projectId}/members`
      const memberRef = db.collection(storageURL).doc(Math.random().toString(36).slice(2, 8))
      const privateRef = db.collection(`${storageURL}/${memberRef.id}/private`).doc('private')
      if (avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${memberRef.id}`, avatar)
      const publicData = {
        id: memberRef.id,
        type: 'player',
        clubId,
        cohortIds: [cohortId],
        teamContextIds: [`${cohortId}--${teamId}`],
        firstName,
        lastName,
        ...(avatar && ({ avatar: avatarUrl })),
        number,
        createdAt: FieldValue.serverTimestamp(),
      }
      const privateData = {
        id: 'private',
        legalCode,
        birthdate,
        ...(email ? ({ email }) : ({ email: null })),
        ...(parentEmail ? ({ parentEmail }) : ({ parentEmail: null })),
        createdAt: FieldValue.serverTimestamp(),
      }
      await privateRef.set(privateData)
      await memberRef.set(publicData)

      if ((email || parentEmail) && rootGetters['project/hasEnrollRequiresMemberCodeFeatures']) {
        dispatch('sendEmailMemberCode', { memberId: memberRef.id, organizationId, projectId })
      }

      return true
    },

    async sendEmailMemberCode(context, { memberId, organizationId, projectId }) {
      const privateData = (await db.collection(`properties/${organizationId}/projects/${projectId}/members/${memberId}/private`).doc('private').get()).data()
      const lang = context.rootGetters['project/data'].language ?? 'en'
      const projectName = context.rootGetters['project/data'].name
      for (const email of [privateData.email, privateData.parentEmail].filter(v => v)) {
        if (email) await callBackend('emails/project-member-code', { to: email, lang, params: { projectName, code: memberId } })
      }
    },

    async updatePlayer(context, { organizationId, projectId, data }) {
      let avatarUrl
      const { id: memberId, avatar, firstName, lastName, legalCode, birthdate, number, email, parentEmail } = data
      const storageURL = `properties/${organizationId}/projects/${projectId}/members`
      const memberRef = db.collection(storageURL).doc(memberId)
      const privateRef = db.collection(`${storageURL}/${memberId}/private`).doc('private')
      if (avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${memberId}`, avatar)

      const publicData = {
        firstName,
        lastName,
        number,
        avatar: avatar ? avatarUrl : null,
        updatedAt: FieldValue.serverTimestamp(),
      }

      const privateData = {
        legalCode,
        birthdate,
        ...(email ? ({ email }) : ({ email: null })),
        ...(parentEmail ? ({ parentEmail }) : ({ parentEmail: null })),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await privateRef.update(privateData)
      await memberRef.update(publicData)

      return true
    },

    async updateMemberHasCredentials(context, { organizationId, projectId, memberId, hasCredentials }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/members`).doc(memberId).update({ hasCredentials })
      return true
    },

    async deleteMember(context, { organizationId, projectId, memberId }) {
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/members/${memberId}` })
      return true
    },

    async deleteStaffInTeam(context, { organizationId, projectId, cohortId, teamId, memberId }) {
      const teamContextIds = context.getters.members.find(m => m.id === memberId).teamContextIds
      const data = { teamContextIds: [...teamContextIds.filter(tC => tC !== `${cohortId}--${teamId}`)] }
      await db.collection(`properties/${organizationId}/projects/${projectId}/members`).doc(memberId).update(data)
    },

    async deleteTeam(context, { organizationId, projectId, cohortId, teamId }) {
      //
      await Promise.all(context.getters.members.filter(m => m.teamContextIds?.filter(tC => tC === `${cohortId}--${teamId}`).length).map(async member => {
        if (member.teamContextIds.length === 1 && member.type !== 'staffer') await context.dispatch('deleteMember', { organizationId, projectId, memberId: member.id })
        else await db.collection(`properties/${organizationId}/projects/${projectId}/members`).doc(member.id).update({ teamContextIds: FieldValue.arrayRemove(`${cohortId}--${teamId}`) })
      }))
      await await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}` })
      return true
    },

    // Staff
    async createStaff(context, { organizationId, projectId, clubId, data }) {
      let avatarUrl
      const { teamContextIds = [], firstName, lastName, avatar = null, position, positionCustom, legalCode, email, phone } = data
      const storageURL = `properties/${organizationId}/projects/${projectId}/members`
      const memberRef = db.collection(storageURL).doc(Math.random().toString(36).slice(2, 8))
      const privateRef = db.collection(`${storageURL}/${memberRef.id}/private`).doc('private')
      if (data.avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${memberRef.id}`, data.avatar)
      const publicData = {
        id: memberRef.id,
        type: 'staffer',
        clubId,
        ...(teamContextIds.length && { cohortIds: [...teamContextIds.map(tC => tC.split('--')[0])] }),
        teamContextIds,
        firstName,
        lastName,
        ...(avatar && { avatar: avatarUrl }),
        ...(position && { position }),
        ...(positionCustom && { positionCustom }),
        createdAt: FieldValue.serverTimestamp(),
      }
      const privateData = {
        legalCode,
        email,
        ...(phone && { phone }),
        updatedAt: FieldValue.serverTimestamp(),
      }

      await memberRef.set(publicData)
      await privateRef.set(privateData)

      if (email && context.rootGetters['project/hasEnrollRequiresMemberCodeFeatures']) {
        context.dispatch('sendEmailMemberCode', { memberId: memberRef.id, organizationId, projectId })
      }
    },
    async updateStaff(context, { organizationId, projectId, memberId, data }) {
      let avatarUrl
      const { teamContextIds = [], firstName, lastName, avatar = null, position, positionCustom, legalCode, email, phone } = data
      const storageURL = `properties/${organizationId}/projects/${projectId}/members`
      const memberRef = db.collection(storageURL).doc(memberId)
      const privateRef = db.collection(`${storageURL}/${memberId}/private`).doc('private')

      if (avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${memberRef.id}`, avatar)
      const publicData = {
        ...(teamContextIds.length ? { cohortIds: [...teamContextIds.map(tC => tC.split('--')[0])] } : { cohortIds: [] }),
        teamContextIds,
        firstName,
        lastName,
        ...(avatar && { avatar: avatarUrl }),
        ...(position ? { position } : { position: null }),
        ...(positionCustom ? { positionCustom } : { positionCustom: null }),
        updatedAt: FieldValue.serverTimestamp(),
      }

      const privateData = {
        legalCode,
        email,
        ...(phone && { phone }),
        updatedAt: FieldValue.serverTimestamp(),
      }

      await memberRef.update(publicData)
      await privateRef.update(privateData)

      return true
    },

    // team attachments
    async updateTeamAttachments(context, { organizationId, projectId, cohortId, teamId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}`
      const attachments = await Promise.all(data.map(file => uploadFileAndGetObj(storagePath, file)))
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update({ attachments })
      return true
    },
  },
}
