import i18n from '@/plugins/i18n'
import store from '@/store'
import pushNotificationsRoutes from '@/modules/pushNotifications/clusterRouter'
import guildMatchesRouter from '@/modules/guildMatches/clusterRouter'
import postRoutes from '@/modules/post/clusterRouter'
import vendorRoutes from '@/modules/vendors/routes/clusterRouter'

export default [
  {
    path: '/organization/:organizationId/cluster/:clusterId',
    name: 'cluster',
    component: () => import('@/modules/cluster/Cluster.vue'),
    redirect: { name: 'cluster-projects' },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, clusterId } = to.params
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        await store.dispatch('guildMatches/bindGuildMatches', organizationId)
        await store.dispatch('organization/bind', organizationId)
        await store.dispatch('organization/bindProjects', organizationId)
        await store.dispatch('organization/bindRolePresets', { organizationId })
        await store.dispatch('organization/bindClusters', organizationId)
        await store.dispatch('vendors/bindVendors', { organizationId })
        if (store.getters['organization/isGuild']) await store.dispatch('organization/bindAuTrips', { organizationId })
      } else {
        if (!store.getters['guildMatches/guildMatches']?.length) await store.dispatch('guildMatches/bindGuildMatches', organizationId)
        if (!store.getters['organization/projects']?.length) await store.dispatch('organization/bindProjects', organizationId)
        if (!store.getters['organization/clusters']?.length) await store.dispatch('organization/bindClusters', organizationId)
        if (!store.state.organization?.dbRolePresets) await store.dispatch('organization/bindRolePresets', { organizationId })
        if (!store.getters['vendors/vendors']?.length) await store.dispatch('vendors/bindVendors', { organizationId })
        if (store.getters['organization/isGuild'] && !store.state.organization?.dbAuTrips?.length) await store.dispatch('organization/bindAuTrips', { organizationId })
      }

      if (store.state.cluster.dbData?.id !== clusterId) {
        const cluster = await store.dispatch('cluster/bind', { organizationId, clusterId })
        if (!cluster) next({ name: '404' })
        await store.dispatch('cluster/unbindPlayers')
        await store.dispatch('cluster/bindManagers', { organizationId, clusterId })
        if (!store.state.organization.dbProjects?.length) await store.dispatch('cluster/bindProjects', { organizationId, clusterId })
        await store.dispatch('cluster/bindStats', { organizationId, clusterId })
        await store.dispatch('cluster/bindBenefitRequests', { organizationId, clusterId })
        await store.dispatch('organization/bindLaLigaTeams', { organizationId })
      } else {
        if (!store.state.cluster.dbManagers?.length) await store.dispatch('cluster/bindManagers', { organizationId, clusterId })
        if (!store.getters['cluster/projects']?.length) await store.dispatch('cluster/bindProjects', { organizationId, clusterId })
        if (!store.state.cluster.dbBenefitRequests?.length) await store.dispatch('cluster/bindBenefitRequests', { organizationId, clusterId })
        if (!store.state.organization.dbLaLigaTeams?.length) await store.dispatch('organization/bindLaLigaTeams', { organizationId })
      }
      store.commit('loader/hide')
      if (!(store.getters['cluster/isUserClusterCollaborator'] || store.getters['organization/isUserAdmin'])) next({ name: '404' })
      next()
    },
    children: [
      {
        path: 'projects',
        name: 'cluster-projects',
        component: () => import('@/modules/organization/ProjectList'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('common.projects')} - OWQLO`,
          headerTitle: () => i18n.t('common.projects'),
        },
        props: true,
      },
      {
        path: 'settings',
        name: 'cluster-edit',
        component: () => import('@/modules/cluster/EditCluster'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.settings'),
        },
        props: true,
      },
      {
        path: 'au-trips',
        name: 'cluster-auTrips',
        component: () => import('@/modules/auTrips/AuTripList.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('cluster.sections.auTrips')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.auTrips'),
        },
        props: true,
      },
      {
        path: 'au-trips/create',
        name: 'cluster-auTrips-create',
        component: () => import('@/modules/auTrips/EditAuTrip.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('cluster.sections.trips')} - OWQLO`,
          headerTitle: () => i18n.t('auTrips.editAuTrip.create'),
        },
        props: true,
      },
      {
        path: 'au-trips/edit/:auTripId',
        name: 'cluster-auTrips-edit',
        component: () => import('@/modules/auTrips/EditAuTrip.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('cluster.sections.trips')} - OWQLO`,
          headerTitle: () => i18n.t('auTrips.editAuTrip.edit'),
        },
        props: true,
      },
      {
        path: 'au-trips/detail/:auTripId',
        name: 'cluster-auTrips-buys',
        component: () => import('@/modules/auTrips/DetailAuTrip.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('cluster.sections.trips')} - OWQLO`,
          headerTitle: () => i18n.t('auTrips.detailAuTrip.title'),
        },
        props: true,
      },
      {
        path: 'benefit-requests',
        name: 'cluster-benefits-requests',
        component: () => import('@/modules/cluster/BenefitRequestList.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('cluster.sections.benefitRequests')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.benefitRequests'),
        },
        props: true,
      },
      {
        path: 'subscribers',
        name: 'cluster-subscribers',
        component: () => import('@/modules/cluster/SubscriberList.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('project.sections.players-suscribers')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.players-suscribers'),
        },
        props: true,
      },
      {
        path: 'subscribers/:subscriberId',
        name: 'cluster-subscriber',
        component: () => import('@/modules/organization/Subscriber.vue'),
        meta: {
          title: () => `${store.getters['cluster/data'].name} - ${i18n.t(`project.sections.players-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.sections.players-${store.getters['cluster/data']?.type}`),
        },
        props: true,
      },
      {
        path: 'staff-list',
        name: 'cluster-staff-list',
        component: () => import('@/modules/cluster/StaffList'),
        meta: {
          title: () => `${store.getters['cluster/data'].name} - ${i18n.t('cluster.sections.managers')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.managers'),
        },
        props: true,
      },
      ...pushNotificationsRoutes,
      ...guildMatchesRouter,
      ...postRoutes,
      ...vendorRoutes,
    ],
  },
]
