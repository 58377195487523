import i18n from '@/plugins/i18n'
import store from '@/store'
import pushNotificationsRoutes from '@/modules/pushNotifications/organizationRouter'
import guildMatchesRouter from '@/modules/guildMatches/organizationRouter'
import getSidebarItems from './getSidebarItems'
import postRoutes from '@/modules/post/organizationRouter'
import orderRoutes from '@/modules/orders/organizationRouter'
import subscriberProjectRoutes from '@/modules/projectUsers/subscriber/routes/organizationRouter'
import vendorRoutes from '@/modules/vendors/routes/organizationRouter'

export default [
  {
    path: '/organization/:organizationId',
    name: 'organization',
    component: () => import('@/modules/organization/Organization'),
    redirect: { name: 'organization-projects' },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId } = to.params

      await store.dispatch('project/unbind')
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        const entity = await store.dispatch('organization/bind', organizationId)
        const isGuild = store.getters['organization/isGuild']
        if (!entity) next({ name: '404' })
        if (entity.sport !== store.getters['organization/sport']?.id) await store.dispatch('organization/bindSport', { sportId: entity.sport })
        await store.dispatch('post/unbindPosts')
        await store.dispatch('organization/unbindBuys')
        await store.dispatch('orders/unbindOrganizationProductBuys')
        await store.dispatch('orders/unbindVendorProductBuys')
        await store.dispatch('organization/bindRolePresets', { organizationId })
        await store.dispatch('organization/bindEventLog', organizationId)
        await store.dispatch('organization/bindClusters', organizationId)
        await store.dispatch('organization/bindProjects', organizationId)
        await store.dispatch('organization/bindAdmins', organizationId)
        await store.dispatch('vendors/bindVendors', { organizationId })
        if (isGuild) {
          await store.dispatch('organization/bindlaligaAdmins', organizationId)
          await store.dispatch('organization/bindLaLigaTeams', { organizationId })
        }
        if (isGuild && store.getters['organization/islaligaAdmin']) {
          await store.dispatch('organization/bindBenefitRequests', { organizationId })
          await store.dispatch('organization/bindAuTrips', { organizationId })
        }
        await store.dispatch('organization/bindTerms', organizationId)
        await store.dispatch('stats/bindStats', organizationId)
        if (store.state.organization?.dbData?.sport === 'guild') await store.dispatch('guildMatches/bindGuildMatches', organizationId)
        await store.dispatch('organization/unbindProducts')
        await store.dispatch('organization/bindProducts', { organizationId })
        await store.dispatch('products/bindTags')
        await store.dispatch('products/bindFamilies')
        await store.dispatch('organization/bindCoupons', organizationId)
        await store.dispatch('organization/bindSubscribers', organizationId)
      } else {
        if (!store.state.organization?.dbRolePresets) await store.dispatch('organization/bindRolePresets', { organizationId })
        if (!store.state.organization?.dbSport) await store.dispatch('organization/bindSport', { sportId: store.state.organization.sport })
        if (!store.state.organization?.dbEventLog?.length) await store.dispatch('organization/bindEventLog', organizationId)
        if (!store.state.organization?.dbClusters?.length) await store.dispatch('organization/bindClusters', organizationId)
        if (!store.state.organization?.dbProjects?.length) await store.dispatch('organization/bindProjects', organizationId)
        if (!store.state.organization?.dbAdmins?.length) await store.dispatch('organization/bindAdmins', organizationId)
        if (!store.state.vendors.dbVendors.length) await store.dispatch('vendors/bindVendors', { organizationId })
        if (!store.state.organization?.dbTerms?.length) await store.dispatch('organization/bindTerms', organizationId)
        if (!store.state.stats?.dbStats?.length) await store.dispatch('stats/bindStats', organizationId)
        if (store.state.organization?.dbData?.sport === 'guild'
          && !store.state.guildMatches?.dbGuildMatches?.length) await store.dispatch('guildMatches/bindGuildMatches', organizationId)
        if (store.getters['superadmin/isUserSuperadmin']) {
          if (!store.state.organization?.dbProducts.length) await store.dispatch('organization/bindProducts', { organizationId })
          if (!store.state.products.dbTags.length) await store.dispatch('products/bindTags')
          if (!store.state.products.dbFamilies.length) await store.dispatch('products/bindFamilies')
        }
        if (store.getters['organization/isGuild'] && store.getters['organization/islaligaAdmin']) {
          if (!store.state.organization?.dbBenefitRequests?.length) await store.dispatch('organization/bindBenefitRequests', { organizationId })
          if (!store.state.organization?.dbAuTrips?.length) await store.dispatch('organization/bindAuTrips', { organizationId })
          if (!store.state.organization?.dbLaLigaTeams?.length) await store.dispatch('organization/bindLaLigaTeams', { organizationId })
        }
        if (!store.state.organization?.dbCoupons?.length) await store.dispatch('organization/bindCoupons', organizationId)
        if (!store.state.organization?.dbSubscribers?.length) await store.dispatch('organization/bindSubscribers', organizationId)
      }
      store.commit('loader/hide')

      // Security
      const userOrganizationIds = [...store.getters['user/adminOrganizationIds'], ...store.getters['user/collabOrganizationIds']]
      const isUserOrganizationStaff = userOrganizationIds.includes(to.params.organizationId)
      if (!(isUserOrganizationStaff || store.getters['superadmin/isUserSuperadmin'])) next({ name: '404' })
      const sidebarItems = getSidebarItems()
      const section = sidebarItems.find(item => item.route?.name === to.name)
      if (section) {
        if (sidebarItems.find(item => item.id === section.id)) next()
        else next({ name: '404' })
      } else next()
    },
    children: [
      {
        path: 'settings',
        name: 'organization-settings',
        component: () => import('@/modules/organization/views/OrganizationSettingsView.vue'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.settings'),
        },
        props: true,
      },
      {
        path: 'terms',
        name: 'organization-terms',
        component: () => import('@/modules/organization/Terms'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.terms')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.terms'),
        },
        props: true,
      },
      {
        path: 'projects',
        name: 'organization-projects',
        component: () => import('@/modules/organization/ProjectList'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.projects')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.projects'),
        },
        props: true,
      },
      {
        path: 'log',
        name: 'organization-eventLog',
        component: () => import('@/components/EventLog'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.eventLog')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.eventLog'),
        },
        props: true,
      },
      {
        path: 'staff-list/:typeId?',
        name: 'organization-staff-list',
        component: () => import('@/modules/organization/StaffList'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.people')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.people'),
        },
        props: true,
      },
      {
        path: 'stats',
        name: 'organization-statistics',
        component: () => import('@/modules/stats/Stats'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.stats')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.stats'),
        },
        props: true,
      },
      {
        path: 'subscribers',
        name: 'organization-subscribers',
        component: () => import('@/modules/organization/SubscriberList.vue'),
        meta: {
          title: () => `${store.getters['organization/data'].name} - ${i18n.t('project.sections.players-suscribers')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.players-suscribers'),
        },
        props: true,
      },
      {
        path: 'subscribers/:subscriberId',
        name: 'organization-subscriber',
        component: () => import('@/modules/organization/Subscriber'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.playerDetail')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.playerDetail'),
        },
        props: true,
      },
      {
        path: 'au-trips',
        name: 'organization-auTrips',
        component: () => import('@/modules/auTrips/AuTripList.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('cluster.sections.auTrips')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.auTrips'),
        },
        props: true,
      },
      {
        path: 'au-trips/detail/:auTripId',
        name: 'organization-auTrips-buys',
        component: () => import('@/modules/auTrips/DetailAuTrip.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('cluster.sections.trips')} - OWQLO`,
          headerTitle: () => i18n.t('auTrips.detailAuTrip.title'),
        },
        props: true,
      },
      {
        path: 'benefit-requests',
        name: 'organization-benefits-requests',
        component: () => import('@/modules/organization/BenefitRequestList.vue'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('cluster.sections.benefitRequests')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.benefitRequests'),
        },
        props: true,
      },
      {
        path: 'payments-history',
        name: 'organization-payments-history',
        component: () => import('@/modules/payments/PaymentsHistory'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('common.history')} - OWQLO`,
          headerTitle: () => i18n.t('common.history'),
        },
        props: true,
      },
      {
        path: 'coupons',
        name: 'organization-coupons',
        component: () => import('@/modules/coupons/CouponsList.vue'),
        meta: {
          title: () => `${store.getters['organization/data'].name} - ${i18n.t('project.sections.coupons')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.coupons'),
        },
        props: true,
      },
      {
        path: 'products',
        name: 'organization-products',
        component: () => import('@/modules/products/views/ProductListView.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      {
        path: 'product/create',
        name: 'organization-product-create',
        component: () => import('@/modules/products/views/ProductForm.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      {
        path: 'product/edit/:archetypeId',
        name: 'organization-product-edit',
        component: () => import('@/modules/products/views/ProductForm.vue'),
        meta: {
          title: () => `${store.getters['organization/data']?.name} - ${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      ...pushNotificationsRoutes,
      ...guildMatchesRouter,
      ...postRoutes,
      ...orderRoutes,
      ...subscriberProjectRoutes,
      ...vendorRoutes,
    ],
  },
  {
    path: '/privacy-policy',
    name: 'organization-privacy-policy',
    component: () => import('@/modules/organization/views/OrganizationPrivacyPolicy.vue'),
    meta: {
      title: () => `${i18n.t('organization.sections.terms')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.terms'),
    },
  },
]
