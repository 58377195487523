import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import user from '@/modules/user/store'
import superadmin from '@/modules/superadmin/store'
import organization from '@/modules/organization/store'
import project from '@/modules/project/store'
import activity from '@/modules/activity/store'
import stats from '@/modules/stats/store'
import alert from '@/modules/alert/store'
import loader from '@/modules/loader/store'
import comment from '@/modules/comment/store'
import streaming from '@/modules/streaming/store'
import pushNotifications from '@/modules/pushNotifications/store'
import dynamicForm from '@/modules/dynamicForm/store'
import competition from '@/modules/competition/store'
import club from '@/modules/club/store'
import clubEnrollmentForm from '@/modules/clubEnrollmentForm/store'
import clubHotelsForm from '@/modules/clubHotelsForm/store'
import guildBenefitRequestsForm from '@/modules/guildBenefitRequestsForm/store'
import academy from '@/modules/academy/store'
import team from '@/modules/team/store'
import projectUsers from '@/modules/projectUsers/store'
import cluster from '@/modules/cluster/store'
import guild from '@/modules/guild/store'
import guildMatches from '@/modules/guildMatches/store'
import post from '@/modules/post/store'
import calendar from '@/modules/calendar/store'
import asset from '@/modules/asset/store'
import locations from '@/modules/locations/store'
import payments from '@/modules/payments/store'
import products from '@/modules/products/store'
import coupons from '@/modules/coupons/store'
import auTrips from '@/modules/auTrips/store'
import vendors from '@/modules/vendors/store'
import orders from '@/modules/orders/store'
import subscriber from '@/modules/projectUsers/subscriber/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: null,
  },
  mutations: {
    ...vuexfireMutations,
    setLocale(state, code) {
      state.locale = code
    },
  },
  modules: {
    user,
    superadmin,
    organization,
    project,
    activity,
    stats,
    alert,
    loader,
    comment,
    streaming,
    pushNotifications,
    dynamicForm,
    competition,
    club,
    clubEnrollmentForm,
    clubHotelsForm,
    guildBenefitRequestsForm,
    academy,
    team,
    projectUsers,
    subscriber,
    cluster,
    guild,
    guildMatches,
    post,
    calendar,
    asset,
    locations,
    payments,
    products,
    coupons,
    auTrips,
    vendors,
    orders,
  },
})
