export default {
  profileFields: [
    { id: 'firstName', isRequired: true },
    { id: 'lastName', isRequired: true },
    { id: 'phone', isRequired: true },
    { id: 'email', isRequired: false },
    { id: 'legalCode', isRequired: true },
  ],
  fields: [
    {
      id: 'partnerCode',
      isRequired: true,
      isStatic: false,
      label: 'Número de Socio',
      page: 1,
      type: 'text',
    },
  ],
}
