import { firestoreAction } from 'vuexfire'
import { db } from '@/services/firebase'
import { strToUniqueId } from '@/utils/formatters'

export default {
  namespaced: true,
  state: () => ({
    dbLocations: [],
  }),
  getters: {
    locations: state => state.dbLocations,
  },
  actions: {
    // bind
    bindLocations: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbLocations',
      db.collection(`properties/${organizationId}/projects/${projectId}/locations`),
    )),
    // create
    async createLocation(context, { organizationId, projectId, data }) {
      const ref = db.collection(`properties/${organizationId}/projects/${projectId}/locations`).doc(strToUniqueId(data.name))
      await ref.set({ id: ref.id, ...data })
      return true
    },
    // update
    async updateLocation(context, { organizationId, projectId, locationId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/locations`).doc(locationId).update(data)
      return true
    },
    // delete
    async deleteReferenceAreaLocation(context, { projectId, locationId, areaId }) {
      const matchRefs = (await db.collectionGroup('matches').where('locationId', '==', locationId).where('areaId', '==', areaId)
        .where('projectId', '==', projectId).get())
        .docs.map(s => s.ref)
      await Promise.all(matchRefs.map(ref => ref.update({ areaId: null })))
      return true
    },
    async deleteLocation(context, { organizationId, projectId, locationId }) {
      if (context.rootGetters['project/isCompetition']) {
        const matchRefs = (await db.collectionGroup('matches').where('locationId', '==', locationId).where('projectId', '==', projectId).get())
          .docs.map(s => s.ref)
        await Promise.all(matchRefs.map(ref => ref.update({ locationId: null, areaId: null })))
      }

      if (!context.rootGetters['project/isCompetition']) {
        const activitiesRefs = (await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).where('locationId', '==', locationId).get())
          .docs.map(a => a.ref)
        await Promise.all(activitiesRefs.map(ref => ref.update({ locationId: null, areaId: null })))
      }

      await db.collection(`properties/${organizationId}/projects/${projectId}/locations`).doc(locationId).delete()

      return true
    },
  },
}
