import { db, FieldValue, uploadFileAndGetUrl, runCloudFunction } from '@/services/firebase'
import i18n from '@/plugins/i18n'
import firebaseConfig from '@/config/firebase'
import sitesUrls from '@/enums/sitesUrls'
import { callBackend } from '../../services/firebase'

export default {
  namespaced: true,
  actions: {

    // Create
    async create(context, { organizationId, projectId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/clubEnrollments/${data.id}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      if (data.dynamicForm) {
        data.dynamicForm = await context.dispatch('dynamicForm/parseForm', { form: data.dynamicForm, storagePath }, { root: true })
      }
      await db.collection(`properties/${organizationId}/projects/${projectId}/clubEnrollments`).doc(data.id).set({
        ...data,
        logo,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      // Send email
      if (!context.rootState.project.dbData.emails?.length) return true
      const adminUrl = sitesUrls[firebaseConfig.projectId] ?? 'http://localhost:8080'
      Promise.all(context.rootState.project.dbData.emails.map(async email => {
        const to = email
        const params = {
            templateId: 'd-2a367244a8e94dd1a1cf6bf893bd86f3',
            dynamicTemplateData: {
              projectName: context.rootState.project.dbData.name,
              lang: context.rootState.project.dbData.language ?? 'en',
              clubName: data.name,
              clubEnrollmentLink: `${adminUrl}/organization/${organizationId}/project/${projectId}/club-enrollments?clubEnrollmentId=${data.id}`,
              teamsInfo: Object.entries(data.teamCountByCohortId).map(([cohortId, count]) => `
              ${i18n.t('common.category')}: <b>${context.rootGetters['competition/cohorts'].find(c => c.id === cohortId).name}</b><br>${i18n.t('team.count')}: <b>${count}</b>`).join('<br><br>'),
            },
        }
        await callBackend('emails/dynamic-template', { to, params })
      }))
      return true
    },
  },
}
