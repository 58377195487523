import i18n from '@/plugins/i18n'

export default [
  {
    path: '/organization/:organizationId/project/:projectId/club/:clubId/hotels-form',
    name: 'club-hotels-form',
    component: () => import('@/modules/clubHotelsForm/MainView'),
    props: true,
    meta: {
      title: () => `${i18n.t('project.sections.accomodations')} - OWQLO`,
      headerTitle: () => `${i18n.t('project.sections.accomodations')}`,
    },
    redirect: { name: 'club-hotels-form-step-1' },
    children: [
      {
        path: 'step-1',
        name: 'club-hotels-form-step-1',
        component: () => import('@/modules/clubHotelsForm/steps/1ContactPerson'),
        meta: {
          stepId: '1',
          title: () => `${i18n.t('project.sections.accomodations')} - OWQLO`,
          headerTitle: () => `${i18n.t('project.sections.accomodations')}`,
        },
        props: true,
      },
      {
        path: 'step-2',
        name: 'club-hotels-form-step-2',
        component: () => import('@/modules/clubHotelsForm/steps/2AddTeams'),
        meta: {
          stepId: '2',
          title: () => `${i18n.t('project.sections.accomodations')} - OWQLO`,
          headerTitle: () => `${i18n.t('project.sections.accomodations')}`,
        },
        props: true,
      },
      {
        path: 'step-3',
        name: 'club-hotels-form-step-3',
        component: () => import('@/modules/clubHotelsForm/steps/3Bookings'),
        meta: {
          stepId: '3',
          title: () => `${i18n.t('project.sections.accomodations')} - OWQLO`,
          headerTitle: () => `${i18n.t('project.sections.accomodations')}`,
        },
        props: true,
      },
      {
        path: 'step-4',
        name: 'club-hotels-form-step-4',
        component: () => import('@/modules/clubHotelsForm/steps/4Confirmation'),
        meta: {
          stepId: '4',
          title: () => `${i18n.t('project.sections.accomodations')} - OWQLO`,
          headerTitle: () => `${i18n.t('project.sections.accomodations')}`,
        },
        props: true,
      },
    ],
  },
]
