import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'schedule',
    name: 'calendar-schedule',
    component: () => import('@/modules/calendar/views/lists/CalendarList.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.schedule')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.schedule'),
    },
    props: true,
  },
]
