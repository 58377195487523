import { db, FieldValue } from '@/services/firebase'

export default {
  namespaced: true,
  actions: {
    async createCoupon(context, { data }) {
      const couponRef = db.collection('coupons').doc(data.code)
      const user = context.rootGetters['user/data']
      const coupon = {
        ...data,
        id: data.code,
        createdBy: user.id,
        uses: 0,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await couponRef.set(coupon)
    },

    async endDiscount(context, { couponId, expiryDate }) {
      const couponRef = db.collection('coupons').doc(couponId)
      await couponRef.update({ expiryDate })
    },
  },
}
