import getUserEntityIds from './getUserEntityIds'

export const getOrganizationApp = organizationId => {
  if (['VBvMPUtcacwtm76lkj3I', 'owqlo-guild'].includes(organizationId)) return 'au'
  return 'owqlo'
}

export const getUserApp = user => {
  const organizationIds = getUserEntityIds(user, 'organization', 'subscriber')
  if (organizationIds.some(id => this.getOrganizationApp(id) === 'au')) return 'au'
  return 'owqlo'
}
