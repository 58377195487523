import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'news',
    name: 'cluster-posts',
    component: () => import('@/modules/post/Posts'),
    meta: {
      title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('project.sections.posts')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.posts'),
    },
    props: true,
  },
  {
    path: 'create-news',
    name: 'cluster-create-post',
    component: () => import('@/modules/post/EditPost'),
    meta: {
      title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('project.sections.createPost')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.createPost'),
    },
    props: true,
  },
  {
    path: 'edit-post/:postId',
    name: 'cluster-edit-post',
    component: () => import('@/modules/post/EditPost'),
    meta: {
      title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('project.sections.editPost')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.editPost'),
    },
    props: true,
  },
]
