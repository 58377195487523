import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'structure',
    name: 'competition-structure',
    component: () => import('@/modules/competition/structure/views/CompetitionStructureView.vue'),
    redirect: { name: 'competition-structure-categories' },
    props: true,
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('competition.sections.structure')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.structure'),
    },
    children: [
      {
        path: 'categories',
        name: 'competition-structure-categories',
        component: () => import('@/modules/competition/structure/views/CompetitionCategoriesView.vue'),
        props: true,
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('competition.sections.structure')} - OWQLO`,
          headerTitle: () => i18n.t('competition.sections.structure'),
        },
      },
      {
        path: 'rules',
        name: 'competition-structure-rules',
        component: () => import('@/modules/competition/structure/views/CompetitionRulesView.vue'),
        props: true,
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('competition.sections.structure')} - OWQLO`,
          headerTitle: () => i18n.t('competition.sections.structure'),
        },
      },
    ],
  },
  {
    path: '/organization/:organizationId/project/:projectId/clubs-forms',
    name: 'competition-club-enrollment-form-edit',
    component: () => import('@/modules/competition/EditClubEnrollmentFormTemplate'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.clubsForm')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.clubsForm'),
    },
    props: true,
  },
  {
    path: 'club-enrollments',
    name: 'competition-club-enrollments',
    component: () => import('@/modules/competition/ClubEnrollmentList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.clubEnrollments')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.clubEnrollments'),
    },
    props: true,
  },
  {
    path: 'groups',
    name: 'competition-groups',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.groups')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.groups'),
    },
    props: route => ({ ...route.params, type: 'groups' }),
  },
  {
    path: 'groups/create-cohort-groups/:cohortId',
    name: 'competition-create-cohort-groups',
    component: () => import('@/modules/competition/groups/CreateCohortGroups'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.groups')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.groups'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, cohortId } = to.params
      if (store.getters['competition/currentMatchesEntity'].type !== 'cohort' || store.getters['competition/currentMatchesEntity'].id !== cohortId) {
        store.commit('loader/show')
        await store.dispatch('competition/bindCohortGroups', { organizationId, projectId, cohortId })
        await store.dispatch('competition/bindCohortTeams', { organizationId, projectId, cohortId })
        store.state.competition.currentMatchesEntity = {}
        store.commit('loader/hide')
      }
      next()
    },
  },
  {
    path: 'brackets',
    name: 'competition-brackets',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.brackets')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.brackets'),
    },
    props: route => ({ ...route.params, type: 'brackets' }),
  },
  {
    path: 'brackets/create-cohort-brackets/:cohortId',
    name: 'competition-create-cohort-brackets',
    component: () => import('@/modules/competition/brackets/CreateCohortBrackets'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.brackets')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.brackets'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, cohortId } = to.params
      if (store.getters['competition/currentMatchesEntity'].type !== 'cohort' || store.getters['competition/currentMatchesEntity'].id !== cohortId) {
        store.commit('loader/show')
        await store.dispatch('competition/bindCohortGroups', { organizationId, projectId, cohortId })
        await store.dispatch('competition/bindCohortTeams', { organizationId, projectId, cohortId })
        store.state.competition.currentMatchesEntity = {}
        store.commit('loader/hide')
      }
      next()
    },
  },
  {
    path: 'clubs',
    name: 'competition-clubs',
    component: () => import('@/modules/competition/ClubList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.clubs')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.clubs'),
    },
    props: true,
  },
  {
    path: 'matches',
    name: 'competition-matches-filter',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.cohortsMatches')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.cohortsMatches'),
    },
    props: route => ({ ...route.params, type: 'matches' }),
  },
  {
    path: 'matches/by-cohort/:cohortId',
    name: 'competition-matches-by-cohort',
    component: () => import('@/modules/competition/matches/MatchList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchesByCohort')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchesByCohort'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, cohortId } = to.params
      if (store.getters['competition/currentMatchesEntity'].type !== 'cohort' || store.getters['competition/currentMatchesEntity'].id !== cohortId) {
        store.commit('loader/show')
        await store.dispatch('competition/bindCohortGroups', { organizationId, projectId, cohortId })
        await store.dispatch('competition/bindCohortTeams', { organizationId, projectId, cohortId })
        await store.dispatch('competition/bindCohortRounds', { organizationId, projectId, cohortId })
        await store.dispatch('competition/bindCohortMatches', { organizationId, projectId, cohortId })
        store.commit('loader/hide')
        store.state.competition.currentMatchesEntity = { type: 'cohort', id: cohortId }
      }
      next()
    },
  },
  {
    path: 'matches/by-club/:clubId',
    name: 'competition-matches-by-club',
    component: () => import('@/modules/competition/matches/MatchList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchesByClub')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchesByClub'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, clubId } = to.params
      if (store.getters['competition/currentMatchesEntity'].type !== 'club' || store.getters['competition/currentMatchesEntity'].id !== clubId) {
        store.commit('loader/show')
        await store.dispatch('competition/bindClubMatches', { projectId, clubId })
        await store.dispatch('competition/readTeamsRoundsGroupsForMatches', { organizationId, projectId })
        store.commit('loader/hide')
        store.state.competition.currentMatchesEntity = { type: 'club', id: clubId }
      }
      next()
    },
  },
  {
    path: 'matches/by-location/:locationId',
    name: 'competition-matches-by-location',
    component: () => import('@/modules/competition/matches/MatchList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchesByLocation')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchesByLocation'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, locationId } = to.params
      if (store.getters['competition/currentMatchesEntity'].type !== 'location' || store.getters['competition/currentMatchesEntity'].id !== locationId) {
        store.commit('loader/show')
        await store.dispatch('competition/bindLocationMatches', { projectId, locationId })
        await store.dispatch('competition/readTeamsRoundsGroupsForMatches', { organizationId, projectId })
        store.commit('loader/hide')
        store.state.competition.currentMatchesEntity = { type: 'location', id: locationId }
      }
      next()
    },
  },
  {
    path: 'matches/by-cohort/:cohortId/:matchId/edit',
    name: 'match-edit',
    component: () => import('@/modules/competition/matches/EditMatch'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('common.match')} - OWQLO`,
      headerTitle: () => i18n.t('common.match'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, cohortId, matchId } = to.params
      const match = store.getters['competition/matches'].find(m => m.id === matchId && m.cohortId === cohortId)
      if (!match) {
        store.state.competition.dbMatches = [await store.dispatch('competition/readMatch', { organizationId, projectId, cohortId, matchId })]
        await store.dispatch('competition/readTeamsRoundsGroupsForMatches', { organizationId, projectId })
      }
      next()
    },
  },
  {
    path: 'ranking',
    name: 'competition-ranking-filter',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.ranking')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.ranking'),
    },
    props: route => ({ ...route.params, type: 'ranking' }),
  },
  {
    path: 'ranking/by-cohort/:cohortId',
    name: 'competition-ranking-by-cohort',
    component: () => import('@/modules/competition/ranking/RankingList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.ranking')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.ranking'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, cohortId } = to.params
      if (store.getters['competition/currentMatchesEntity'].type !== 'cohort' || store.getters['competition/currentMatchesEntity'].id !== cohortId) {
        store.commit('loader/show')
        await store.dispatch('competition/bindCohortGroups', { organizationId, projectId, cohortId })
        await store.dispatch('competition/bindCohortTeams', { organizationId, projectId, cohortId })
        store.state.competition.currentMatchesEntity = {}
        store.commit('loader/hide')
      }
      next()
    },
  },
  {
    path: 'create-bans',
    name: 'competition-create-bans',
    component: () => import('@/modules/competition/bans/CreateBans'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.createBans')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.createBans'),
    },
    props: true,
  },
  {
    path: 'manage-bans',
    name: 'competition-manage-bans',
    component: () => import('@/modules/competition/bans/ManageBans'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.manageBans')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.manageBans'),
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId } = to.params
      store.commit('loader/show')
      await store.dispatch('competition/bindEvents', { projectId, typeId: 'red_card' })
      const matchesToDownload = store.getters['competition/events'].map(({ cohortId, matchId }) => ({ cohortId, matchId }))
        .filter((v, i, a) => a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.matchId === v.matchId)) === i)
        .filter(({ matchId, cohortId }) => !store.getters['competition/events'].find(r => r.cohortId === cohortId && r.id === matchId))
      const teamsToDownload = store.getters['competition/events'].map(({ cohortId, teamId }) => ({ cohortId, teamId }))
        .filter((v, i, a) => a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.teamId === v.teamId)) === i)
        .filter(({ teamId, cohortId }) => !store.getters['competition/events'].find(r => r.cohortId === cohortId && r.id === teamId))
      const membersToDownload = store.getters['competition/events'].map(({ cohortId, memberId }) => ({ cohortId, memberId }))
        .filter((v, i, a) => a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.memberId === v.memberId)) === i)
        .filter(({ memberd, cohortId }) => !store.getters['competition/events'].find(r => r.cohortId === cohortId && r.id === memberd))
      store.state.competition.dbMatches = await Promise.all(matchesToDownload.map(match => store
        .dispatch('competition/readMatch', { organizationId, projectId, cohortId: match.cohortId, matchId: match.matchId })))
      store.state.competition.dbTeams = await Promise.all(teamsToDownload.map(team => store
        .dispatch('competition/readTeam', { organizationId, projectId, cohortId: team.cohortId, teamId: team.teamId })))
      store.state.competition.dbMembers = await Promise.all(membersToDownload.map(member => store
        .dispatch('competition/readMember', { organizationId, projectId, memberId: member.memberId })))
      await store.dispatch('competition/readTeamsRoundsGroupsForMatches', { organizationId, projectId })
      store.state.competition.currentMatchesEntity = {}
      store.commit('loader/hide')
      next()
    },
  },
  {
    path: 'info',
    name: 'competition-info',
    component: () => import('@/modules/competition/Info'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('common.info')} - OWQLO`,
      headerTitle: () => i18n.t('common.info'),
    },
    props: true,
  },
]
