import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend, uploadFileAndGetUrl } from '@/services/firebase'
import { strToUniqueId } from '@/utils/formatters'
import i18n from '@/plugins/i18n'
import rolesEnum from '@/enums/rolesEnum'

export default {
  namespaced: true,
  state: () => ({
    dbStages: [],
    dbRegions: [],
    dbCategories: [],
    dbClubs: [],
    dbClubEnrollments: [],
    dbCohorts: [],
    dbGroups: [],
    dbRounds: [],
    dbTeams: [],
    dbMembers: [],
    dbFixtures: [],
    dbMatches: [],
    dbFranchises: [],
    dbBanTypes: [],
    dbEvents: [],
    currentMatchesEntity: {},
  }),
  getters: {
    stages: state => state.dbStages,
    clubs: state => state.dbClubs,
    clubEnrollments: state => state.dbClubEnrollments,
    cohorts: (state, getters, rootState, rootGetters) => state.dbCohorts.map(cohort => {
      const region = getters.regions.find(r => r.id === cohort.regionId)
      const category = getters.categories.find(c => c.id === cohort.categoryId)
      return {
        ...cohort,
        name: `${rootGetters['project/data'].features?.hasRegions && region?.name ? `${region.name} - ` : ''}${category?.name ? `${category.name}` : ''}`,
        isRegularNba: cohort.dataByStageId.elimination.presetType === 'regularNba',
      }
    }),
    matches: state => state.dbMatches,
    locations: state => state.dbLocations,
    regions: state => [...state.dbRegions].sort((a, b) => a?.name.localeCompare(b?.name)),
    categories: state => [...state.dbCategories].sort((a, b) => a?.name.localeCompare(b?.name)),
    groups: state => [...state.dbGroups].sort((a, b) => a?.index - b?.index),
    rounds: state => state.dbRounds,
    teams: (state, getters, rootState, rootGetters) => state.dbTeams.map(team => {
      const franchise = getters.franchises.find(f => f.id === team?.franchiseId)

      return {
        ...team,
        name: rootGetters['organization/isBasketball']
          ? `${team.suffix ? `${team.suffix} - ` : ''}${getters.clubs.find(c => c.id === team.clubId).name}`
          : `${getters.clubs.find(c => c.id === team.clubId).name}${team.suffix ? ` - ${team.suffix}` : ''}`,
        shortName: rootGetters['organization/isBasketball']
          ? `${franchise ? `${franchise.abcName} - ` : ''}${getters.clubs.find(c => c.id === team.clubId).name}`
          : `${team.suffix ? `${team.suffix} - ` : ''}${getters.clubs.find(c => c.id === team.clubId).name}`,
        nameWithCohort: `${getters.cohorts.find(c => c.id === team.cohortId)?.name}${team.suffix ? ` - ${team.suffix}` : ''}`,
        logo: getters.franchises.find(f => f.id === team?.franchiseId)?.logo?.thumbUrl
          ?? getters.clubs.find(club => club.id === team?.clubId)?.logo,
        hasPaid: getters.clubs.find(c => c.id === team.clubId).paymentStatus === 'complete',
      }
    }),
    members: state => state.dbMembers,
    franchises: state => state.dbFranchises,
    banTypes: state => state.dbBanTypes,
    events: state => state.dbEvents,
    currentMatchesEntity: state => state.currentMatchesEntity,
  },
  actions: {
    // bind
    bindStages: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbStages',
      db.collection(`properties/${organizationId}/projects/${projectId}/stages`),
    )),
    bindRegions: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbRegions',
      db.collection(`properties/${organizationId}/projects/${projectId}/regions`),
    )),
    bindCategories: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCategories',
      db.collection(`properties/${organizationId}/projects/${projectId}/categories`),
    )),
    bindFranchises: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef(
      'dbFranchises',
      db.collection(`properties/${organizationId}/franchises`),
    )),
    bindClubs: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbClubs',
      db.collection(`properties/${organizationId}/projects/${projectId}/clubs`),
    )),
    bindClubEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbClubEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/clubEnrollments`),
    )),
    bindBanTypes: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbBanTypes',
      db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`),
    )),
    bindCohorts: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCohorts',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`),
    )),
    //  Cohorts collections
    bindCohortTeams: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId }) => bindFirestoreRef(
      'dbTeams',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`),
    )),
    bindCohortGroups: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId }) => bindFirestoreRef(
      'dbGroups',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/groups`),
    )),
    bindCohortRounds: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId }) => bindFirestoreRef(
      'dbRounds',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/rounds`),
    )),
    bindCohortMatches: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId }) => bindFirestoreRef(
      'dbMatches',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`),
    )),
    // Club collections
    bindClubMatches: firestoreAction(({ bindFirestoreRef }, { projectId, clubId }) => bindFirestoreRef(
      'dbMatches',
      db.collectionGroup('matches').where('projectId', '==', projectId).where('clubIds', 'array-contains', clubId),
    )),
    // Location collections
    bindLocationMatches: firestoreAction(({ bindFirestoreRef }, { projectId, locationId }) => bindFirestoreRef(
      'dbMatches',
      db.collectionGroup('matches').where('projectId', '==', projectId).where('locationId', '==', locationId),
    )),
    //  Collection groups
    bindEvents: firestoreAction(({ bindFirestoreRef }, { projectId, typeId }) => bindFirestoreRef(
      'dbEvents',
      db.collectionGroup('events').where('projectId', '==', projectId).where('typeId', '==', typeId),
    )),

    // Read
    readMatch: async (context, { organizationId, projectId, cohortId, matchId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).doc(matchId).get()).data(),
    hasIncompleteCohortMatches: async (context, { organizationId, projectId, cohortId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).where('date', '==', null).limit(1).get()).docs.length,
    hasIncompleteClubMatches: async (context, { projectId, clubId }) => (await db
      .collectionGroup('matches').where('projectId', '==', projectId).where('clubIds', 'array-contains', clubId).where('date', '==', null).limit(1).get()).docs.length,
    hasClubMatches: async (context, { projectId, clubId }) => (await db
      .collectionGroup('matches').where('projectId', '==', projectId).where('clubIds', 'array-contains', clubId).limit(1).get()).docs.length,
    hasLocationMatches: async (context, { projectId, locationId }) => (await db
      .collectionGroup('matches').where('projectId', '==', projectId).where('locationId', '==', locationId).limit(1).get()).docs.length,
    readRound: async (context, { organizationId, projectId, cohortId, roundId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/rounds`).doc(roundId).get()).data(),
    readGroup: async (context, { organizationId, projectId, cohortId, groupId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/groups`).doc(groupId).get()).data(),
    readTeam: async (context, { organizationId, projectId, cohortId, teamId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).get()).data(),
    hasCohortTeams: async (context, { organizationId, projectId, cohortId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).limit(1).get()).docs.length,
    readCohortTeams: async (context, { organizationId, projectId, cohortId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    readMember: async (context, { organizationId, projectId, memberId }) => (await db
      .collection(`properties/${organizationId}/projects/${projectId}/members`).doc(memberId).get()).data(),
    readTeamsRoundsGroupsForMatches: async ({ getters, state, dispatch }, { organizationId, projectId }) => {
      const roundsToDownload = getters.matches.map(({ cohortId, roundId }) => ({ cohortId, roundId }))
        .filter((v, i, a) => a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.roundId === v.roundId)) === i)
        .filter(({ roundId, cohortId }) => !getters.rounds.find(r => r.cohortId === cohortId && r.id === roundId))
      const groupsToDownload = getters.matches.flatMap(({ cohortId, groupId, teamsByOrigin }) => [
        ...Object.values(teamsByOrigin ?? {}).flatMap(origin => {
          if (origin.type === 'group') return { cohortId, groupId: origin.id }
          if (origin.type === 'fixture') return { cohortId, groupId: `${origin.id.split('-')[0]}-${origin.id.split('-')[1].split('group')[1]}` }
          return null
        }),
        { groupId, cohortId },
      ]).filter((v, i, a) => v && a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.groupId === v.groupId)) === i)
        .filter(({ groupId, cohortId }) => !getters.groups.find(r => r.cohortId === cohortId && r.id === groupId))
      const teamsToDownload = getters.matches.flatMap(({ cohortId, teamsById }) => Object.keys(teamsById).map(teamId => ({ cohortId, teamId })))
        .filter((v, i, a) => a.findIndex(v2 => (v2.cohortId === v.cohortId && v2.teamId === v.teamId)) === i)
        .filter(({ teamId, cohortId }) => !getters.teams.find(r => r.cohortId === cohortId && r.id === teamId))

      await Promise.all(roundsToDownload.map(async ({ cohortId, roundId }) => {
        state.dbRounds.push(await dispatch('readRound', { organizationId, projectId, cohortId, roundId }))
      }))
      await Promise.all(groupsToDownload.map(async ({ cohortId, groupId }) => {
        state.dbGroups.push(await dispatch('readGroup', { organizationId, projectId, cohortId, groupId }))
      }))
      await Promise.all(teamsToDownload.map(async ({ cohortId, teamId }) => {
        state.dbTeams.push(await dispatch('readTeam', { organizationId, projectId, cohortId, teamId }))
      }))
    },
    getMatchesInLocationBetweenDates: async (context, { projectId, startDate, endDate, locationId, areaId }) => {
      const matches = (await db.collectionGroup('matches').where('projectId', '==', projectId)
        .where('locationId', '==', locationId).where('areaId', '==', areaId)
        .where('date', '>', startDate).where('date', '<', endDate).get()).docs.map(s => s.data())
      return matches
    },
    readClubsMatches: async (context, { projectId, clubIds }) => (await db.collectionGroup('matches')
      .where('projectId', '==', projectId).where('clubIds', 'array-contains-any', clubIds).get()).docs.map(s => s.data()),

    getMatches: (context, { organizationId, projectId, isForImport = false }) => callBackend('exports/competition/get-matches', { organizationId, projectId, isForImport }),
    getTeams: (context, { organizationId, projectId }) => callBackend('exports/competition/get-teams', { organizationId, projectId }),
    getPlayers: (context, { organizationId, projectId }) => callBackend('exports/competition/get-players', { organizationId, projectId }),
    getClubs: (context, { organizationId, projectId }) => callBackend('exports/competition/get-clubs', { organizationId, projectId }),
    getClubEnrollments: (context, { organizationId, projectId }) => callBackend('exports/competition/get-clubEnrollments', { organizationId, projectId }),
    getTeamEnrollments: (context, { organizationId, projectId }) => callBackend('exports/competition/get-teamEnrollments', { organizationId, projectId }),
    getRrobinStats: (context, { organizationId, projectId }) => callBackend('exports/competition/get-rrobin-stats', { organizationId, projectId }),
    canMatchBeEdited: (context, { organizationId, projectId, cohortId, matchId }) => callBackend('projects/competition/can-match-be-edited', { organizationId, projectId, cohortId, matchId }),

    approveClubEnrollment(context, { organizationId, projectId, clubEnrollmentId }) {
      return callBackend('clubs/approve-enrollment', { organizationId, projectId, clubEnrollmentId })
    },
    rejectClubEnrollment(context, { organizationId, projectId, clubEnrollmentId }) {
      return callBackend('clubs/reject-enrollment', { organizationId, projectId, clubEnrollmentId })
    },
    mergeWithOtherClub: (context, { organizationId, projectId, baseClubId, extraClubId }) => callBackend('clubs/merge', { organizationId, projectId, baseClubId, extraClubId }),
    switchTeams: (context, { organizationId, projectId, team1Id, team2Id, team1CohortId, team2CohortId }) => callBackend('projects/competition/switch-teams', { organizationId, projectId, team1Id, team2Id, team1CohortId, team2CohortId }),
    getTeamsWithoutPlayers: (context, { organizationId, projectId, minPlayers }) => callBackend('projects/competition/get-teams-without-players', { organizationId, projectId, minPlayers }),
    getIncorrectMatches: (context, { organizationId, projectId }) => callBackend('projects/competition/get-incorrect-matches', { organizationId, projectId }),
    makePendingClubEnrollment(context, { organizationId, projectId, clubEnrollmentId }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/clubEnrollments`).doc(clubEnrollmentId).update({ status: 'pendingApproval' })
    },
    async duplicateTeam({ rootGetters }, { organizationId, projectId, collectionPath, originalCohortId, newCohortId, teamId }) {
      const originalTeamContextId = `${originalCohortId}--${teamId}`
      const newTeamContextId = `${newCohortId}--${teamId}`
      const membersToUpdate = rootGetters['club/members'].filter(member => member.teamContextIds.includes(originalTeamContextId))
      membersToUpdate.map(async member => {
        await db.collection(`${collectionPath}/members`).doc(member.id).update({
          cohortIds: [...new Set([...member.cohortIds, newCohortId])],
          teamContextIds: [...new Set([...member.teamContextIds, newTeamContextId])],
          updatedAt: FieldValue.serverTimestamp(),
        })
      })
      const originalDoc = (await db.collection(`${collectionPath}/cohorts/${originalCohortId}/teams`).doc(teamId).get()).data()
      await callBackend('teams/create', {
        organizationId,
        projectId,
        cohortId: newCohortId,
        data: { ...originalDoc },
      })
    },

    // Create
    async createRegion(context, { organizationId, projectId, regionId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/regions`).doc(regionId).set(data)
    },

    async createCategory(context, { organizationId, projectId, categoryId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).set(data)
    },

    async createCohort(context, { organizationId, projectId, cohortId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId).set(data)
    },

    async createBanType(context, { organizationId, projectId, data }) {
      const ref = await db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`).doc(strToUniqueId(data.reason))
      await ref.set({ id: ref.id, ...data })
      return true
    },

    async createCohortGroups(context, { organizationId, projectId, cohortId, cohortData, preset }) {
      const cohortRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId)
      await context.dispatch('deleteCohortGroups', { organizationId, projectId, cohortId })
      await cohortRef.update(cohortData)
      if (preset === 'default') {
        await Promise.all([...Array(cohortData['dataByStageId.rrobin.groupCount'])].map((_, index) => {
          const id = `rrobin-${index}`
          const name = `${i18n.t('common.group')} ${[...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'][index]}`
          const data = { cohortId, id, index, name, projectId, stageId: 'rrobin' }
          return cohortRef.collection('groups').doc(id).set(data)
        }))
        await cohortRef.update({ 'dataByStageId.elimination.presetType': 'mirrorAlphabet' })
      }
      if (preset === 'regularNba') {
        const groups = [
          { id: 'rrobin-0', index: 0, name: 'East - Atlantic', stageId: 'rrobin', conference: 'east' },
          { id: 'rrobin-1', index: 1, name: 'East - Central', stageId: 'rrobin', conference: 'east' },
          { id: 'rrobin-2', index: 2, name: 'East - Southeast', stageId: 'rrobin', conference: 'east' },
          { id: 'rrobin-3', index: 3, name: 'West - Northwest', stageId: 'rrobin', conference: 'west' },
          { id: 'rrobin-4', index: 4, name: 'West - Pacific', stageId: 'rrobin', conference: 'west' },
          { id: 'rrobin-5', index: 5, name: 'West - Southwest', stageId: 'rrobin', conference: 'west' },
          { id: 'elimination-0', index: 0, name: 'Playoffs', stageId: 'elimination', rrobinGroupPositions: [...Array(8)].map((_, i) => ({ groupPosition: i, positions: 'all' })), losersRoundIndexUpTo: -1 },
        ]
        await Promise.all(groups.map(group => cohortRef.collection('groups').doc(group.id).set({ ...group, cohortId, projectId })))
        await cohortRef.update({ 'dataByStageId.elimination.presetType': 'regularNba', 'dataByStageId.elimination.teamCombinationType': 'sequential' })
      }
      if (preset === 'regularWnba') {
        const groups = [
          { id: 'rrobin-0', index: 0, name: 'East Conference', stageId: 'rrobin' },
          { id: 'rrobin-1', index: 1, name: 'West conference', stageId: 'rrobin' },
          { id: 'elimination-0', index: 0, name: 'Playoffs', stageId: 'elimination', rrobinGroupPositions: [...Array(2)].map((_, i) => ({ groupPosition: i, positions: 'all' })), losersRoundIndexUpTo: -1 },
        ]
        await Promise.all(groups.map(group => cohortRef.collection('groups').doc(group.id).set({ ...group, cohortId, projectId })))
        await cohortRef.update({ 'dataByStageId.elimination.presetType': 'mirrorAlphabet' })
      }
    },
    async createGroup(context, { organizationId, projectId, cohortId, groupId, data }) {
      const ref = await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/groups`).doc(groupId)
      await ref.set({ id: ref.id, ...data })
    },
    async createRoundsAndFixturesAndMatches(context, { organizationId, projectId, cohortId }) {
      await callBackend('projects/competition/create-rounds-and-fixtures-and-matches', { organizationId, projectId, cohortId })
    },

    async createMatch({ state }, { organizationId, projectId, cohortId, previousMatchId }) {
      const previousMatch = state.dbMatches.find(match => match.id === previousMatchId)
      const cohortRef = db.doc(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}`)
      const previousFixture = (await cohortRef.collection('fixtures').doc(previousMatch.fixtureId).get()).data()
      const newFixtureIndex = +previousMatch.fixtureId.split('fixture')[1] + 1
      const newFixtureId = `${previousMatch.fixtureId.split('fixture')[0]}fixture${newFixtureIndex}`
      const newFixture = { ...previousFixture, id: newFixtureId, index: newFixtureIndex, teamsById: {}, teamsByOrigin: {} }
      const newMatch = {
        ...previousMatch,
        id: `${newFixtureId}-match0`,
        fixtureId: newFixtureId,
        teamsById: {},
        teamsByOrigin: {},
        clubIds: [],
        location: null,
        locationId: null,
        areaId: null,
        comments: null,
        date: null,
        tempMatchInfo: null,
        isResultProcessed: false,
        matchPartDurationInMinutes: 0,
        matchOvertimeDurationInMinutes: 0,
        status: 'notStarted',
      }
      await cohortRef.collection('matches').doc(newMatch.id).set(newMatch)
      await cohortRef.collection('fixtures').doc(newFixture.id).set(newFixture)
    },

    // Update
    async moveTeamToGroup(context, { organizationId, projectId, cohortId, groupId, teamId, index }) {
      console.log('move', groupId, teamId, index)
      const cohortRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId)
      await cohortRef.collection('teams').doc(teamId).update({ 'dataByStageId.rrobin.groupId': groupId, 'dataByStageId.rrobin.index': index })
      const nextGroupTeams = context.getters.teams.filter(t => t.cohortId === cohortId && t.dataByStageId?.rrobin?.groupId === groupId
        && t.id !== teamId && t.dataByStageId?.rrobin?.index >= index)
      await Promise.all(nextGroupTeams.map(team => cohortRef.collection('teams').doc(team.id)
        .update({ 'dataByStageId.rrobin.index': team.dataByStageId.rrobin.index + 1 })))
      await context.dispatch('checkIndexTeamsInGroupIsRight', { organizationId, projectId, cohortId, groupId })
    },

    async removeTeamFromGroup(context, { organizationId, projectId, cohortId, groupId, teamId, index }) {
      console.log('remove', groupId, teamId, index)
      if (!groupId) return
      const cohortRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId)
      await cohortRef.collection('teams').doc(teamId).update({ 'dataByStageId.rrobin.groupId': null, 'dataByStageId.rrobin.index': null })
      const nextGroupTeams = context.getters.teams.filter(t => t.cohortId === cohortId && t.dataByStageId?.rrobin?.groupId === groupId
        && t.id !== teamId && t.dataByStageId?.rrobin?.index >= index)
      await Promise.all(nextGroupTeams.map(team => cohortRef.collection('teams').doc(team.id)
        .update({ 'dataByStageId.rrobin.index': team.dataByStageId.rrobin.index - 1 })))
      await context.dispatch('checkIndexTeamsInGroupIsRight', { organizationId, projectId, cohortId, groupId })
    },

    async checkIndexTeamsInGroupIsRight(context, { organizationId, projectId, cohortId, groupId }) {
      const cohortRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId)
      const groupTeams = context.getters.teams.filter(t => t.cohortId === cohortId && t.dataByStageId?.rrobin?.groupId === groupId)
      if (groupTeams?.some((team, i) => team.dataByStageId?.rrobin?.index !== i)) {
        await Promise.all(groupTeams.map((team, index) => {
          if (team.dataByStageId?.rrobin?.index !== index) {
            cohortRef.collection('teams').doc(team.id).update({ 'dataByStageId.rrobin.index': index })
          }
        }))
      }
    },

    async updateTeam(context, { organizationId, projectId, cohortId, teamId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update(data)
      return true
    },

    async updateTeamPointsModifier(context, { organizationId, projectId, cohortId, team, pointsModifier }) {
      const data = {
        'dataByStageId.rrobin.stats.pointsModifier': pointsModifier,
        'dataByStageId.rrobin.stats.points': FieldValue.increment(pointsModifier - (team.dataByStageId.rrobin.stats?.pointsModifier ?? 0)),
      }
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(team.id).update(data)
      const { groupId } = team.dataByStageId.rrobin
      await callBackend('projects/competition/update-rrobin-group-positions', { organizationId, projectId, cohortId, groupId })
      return true
    },

    async updateRegion(context, { organizationId, projectId, regionId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/regions`).doc(regionId).update(data)
    },

    async updateTiebreakers(context, { organizationId, projectId, tiebreakers }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/stages`).doc('rrobin').update({ tiebreakers })
    },

    async updateCategory(context, { organizationId, projectId, categoryId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).update(data)
    },

    async updateCategoryIsEnrollmentClosed(context, { organizationId, projectId, categoryId, isEnrollmentClosed }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).update({ isEnrollmentClosed })
    },

    async updateCohort(context, { organizationId, projectId, cohortId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId).update(data)
    },

    async updateCohortGamesheetConfig(context, { organizationId, projectId, cohortId, gamesheetConfig }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId).update({ gamesheetConfig })
    },

    async updateCohortRegionId(context, { organizationId, projectId, cohortId, regionId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId).update({ regionId })
    },

    async updateMemberBanMatchCount(context, { organizationId, projectId, memberId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/members/`).doc(memberId).update(data)
      return true
    },

    async updateEventBanMatchCount(context, { organizationId, projectId, cohortId, matchId, id, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches/${matchId}/events`).doc(id).update(data)
      return true
    },

    // update matches
    async updateMatch({ state }, { organizationId, projectId, cohortId, matchId, data }) {
      console.log('🚀 ~ file: store.js ~ line 372 ~ updateMatch ~ data', data)
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).doc(matchId).update(dataToUpdate)
      return true
    },

    async finalizeMatch({ rootGetters }, { organizationId, projectId, cohortId, matchId }) {
      const matchRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).doc(matchId)
      try {
        await matchRef.update({ finishedAt: FieldValue.serverTimestamp() })
        await callBackend('projects/competition/finish-match', { organizationId, projectId, cohortId, matchId })
        await matchRef.update({ finishedBy: rootGetters['user/data'].id })
        return true
      } catch (err) {
        await matchRef.update({ finishError: JSON.stringify(err) })
        return false
      }
    },

    recalculateAfterMatchEdit: ({ rootGetters }, { organizationId, projectId, cohortId, matchId }) => callBackend('projects/competition/recalculate-after-match-edit', { organizationId, projectId, cohortId, matchId }),

    async updateBanType(context, { organizationId, projectId, banTypeId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`).doc(banTypeId).update(data)
      return true
    },

    async matchChangeTeam({ getters }, { organizationId, projectId, match, teamIndex, newTeam }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      const oldTeam = getters.teams.find(t => t.id === match[`team${teamIndex}`].id && t.cohortId === match.cohortId)
      await projectRef.collection(`cohorts/${match.cohortId}/matches`).doc(match.id).update({
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        [`teamsById.${newTeam.id}`]: oldTeam?.id ? match.teamsById[oldTeam.id] : { index: teamIndex },
        clubIds: match.clubIds.filter(id => id !== oldTeam?.clubId).concat(newTeam.clubId),
      })
      await projectRef.collection(`cohorts/${match.cohortId}/fixtures`).doc(match.fixtureId).update({
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        [`teamsById.${newTeam.id}`]: oldTeam?.id ? match.teamsById[oldTeam.id] : { index: teamIndex },
      })
      // Update isApproved
      if (oldTeam) await projectRef.collection(`cohorts/${match.cohortId}/teams`).doc(oldTeam.id).update({ isApproved: newTeam.isApproved })
      await projectRef.collection(`cohorts/${match.cohortId}/teams`).doc(newTeam.id).update({ isApproved: true })
      return true
    },

    async matchChangeTeamOrigin({ getters }, { organizationId, projectId, match, teamIndex, newOrigin }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      const oldOrigin = match[`team${teamIndex}`].origin
      const oldTeam = getters.teams.find(t => t.id === match[`team${teamIndex}`].id && t.cohortId === match.cohortId)
      await projectRef.collection(`cohorts/${match.cohortId}/matches`).doc(match.id).update({
        ...(oldOrigin && { [`teamsByOrigin.${oldOrigin.id}-position${oldOrigin.position}`]: FieldValue.delete() }),
        [`teamsByOrigin.${newOrigin.id}-position${newOrigin.position}`]: newOrigin,
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        ...(oldTeam?.id && { clubIds: FieldValue.arrayRemove(oldTeam?.clubId) }),
      })
      await projectRef.collection(`cohorts/${match.cohortId}/fixtures`).doc(match.fixtureId).update({
        ...(oldOrigin && { [`teamsByOrigin.${oldOrigin.id}-position${oldOrigin.position}`]: FieldValue.delete() }),
        [`teamsByOrigin.${newOrigin.id}-position${newOrigin.position}`]: newOrigin,
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
      })
    },
    async unAssignMatchTeamOrigin({ getters }, { organizationId, projectId, match, teamIndex }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      const oldOrigin = match[`team${teamIndex}`].origin
      const oldTeam = getters.teams.find(t => t.id === match[`team${teamIndex}`].id && t.cohortId === match.cohortId)
      await projectRef.collection(`cohorts/${match.cohortId}/matches`).doc(match.id).update({
        ...(oldOrigin && { [`teamsByOrigin.${oldOrigin.id}-position${oldOrigin.position}`]: FieldValue.delete() }),
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        ...(oldTeam?.id && { clubIds: FieldValue.arrayRemove(oldTeam?.clubId) }),
      })
      await projectRef.collection(`cohorts/${match.cohortId}/fixtures`).doc(match.fixtureId).update({
        ...(oldOrigin && { [`teamsByOrigin.${oldOrigin.id}-position${oldOrigin.position}`]: FieldValue.delete() }),
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
      })
    },
    async updateGroup(context, { organizationId, projectId, cohortId, groupId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/groups`).doc(groupId).update(data)
      return true
    },

    // Delete
    async cleanCohortGroups(contex, { organizationId, projectId, cohortId }) {
      const data = { 'dataByStageId.rrobin.groupId': null, 'dataByStageId.rrobin.index': null }
      const teamRefs = (await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`)
        .where('cohortId', '==', cohortId).get()).docs.map(d => d.ref)
      await Promise.all(teamRefs.map(ref => ref.update(data)))
    },

    async deleteCohortGroups(context, { organizationId, projectId, cohortId }) {
      await context.dispatch('cleanCohortGroups', { organizationId, projectId, cohortId })
      const cohortRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId)
      const groupsRefs = (await cohortRef.collection('groups').where('cohortId', '==', cohortId).get()).docs.map(d => d.ref)
      await Promise.all(groupsRefs.map(ref => ref.delete()))
      await cohortRef.update({
        'dataByStageId.rrobin.groupCount': null,
        'dataByStageId.rrobin.teamsPerGroup': null,
        'dataByStageId.elimination.presetType': 'mirrorAlphabet',
      })
    },

    async deleteGroup(context, { organizationId, projectId, cohortId, groupId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/groups`).doc(groupId).delete()
    },

    async deleteRegion(context, { organizationId, projectId, regionId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/regions`).doc(regionId).delete()
    },

    async deleteCategory(context, { organizationId, projectId, categoryId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).delete()
    },

    async deleteCohort(context, { organizationId, projectId, cohortId }) {
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}` })
    },

    async deleteBanType(context, { organizationId, projectId, banTypeId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`).doc(banTypeId).delete()
      return true
    },

    async resetCohort(context, { organizationId, projectId, cohortId }) {
      await callBackend('projects/competition/reset-cohort', { organizationId, projectId, cohortId })
    },

    async deleteMatch(context, { organizationId, projectId, cohortId, matchId }) {
      const match = (await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).doc(matchId).get()).data()
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/fixtures/${match?.fixtureId}` })
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches/${matchId}` })
    },
  },
}
