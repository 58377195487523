import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'payments-settings',
    name: 'cluster-payments-settings',
    component: () => import('@/modules/vendors/views/EditVendorView.vue'),
    meta: {
      title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.vendors'),
    },
    redirect: { name: 'cluster-payments-general-settings' },
    props: true,
    children: [
      {
        path: 'general-settings',
        name: 'cluster-payments-general-settings',
        component: () => import('@/modules/vendors/views/editVendor/SettingsView.vue'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('organization.sections.vendors')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.vendors'),
          viewEntityType: 'cluster',
        },
        props: true,
      },
    ],
  },
]
